<template>
  <div class="home">
    <headbox></headbox>
    <index></index>
    <foot></foot>
  </div>
</template>

<script>
import headbox from "@/components/head";
// import headbox from "@/components/headfloat";
import index from '@/components/index'
import foot from '@/components/foot'


export default {
  name: 'Home',
  components: {
    index ,
    foot ,
    headbox ,
  },
  data() {
    return {

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped>

  ::-webkit-scrollbar{
    display: none;
  }

  .home{
    width: 100%;
    height: auto;
    overflow: scroll;
    font-size: 12px;
  }
</style>
