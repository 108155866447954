<template>
  <div class="home">
    <headbox></headbox>
    <index></index>
<!--    <foot></foot>-->
    <bottom></bottom>
  </div>
</template>

<script>
import headbox from "@/components/headfloat";
import index from '@/components/indexfloat'
import foot from '@/components/foot'
import bottom from '@/components/bottom'

export default {
  name: 'HomeFloat',
  components: {
    index ,
    foot ,
    bottom,
    headbox ,
  },
  data() {
    return {

    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped>

  ::-webkit-scrollbar{
    display: none;
  }

  .home{
    width: 100%;
    height: auto;
    overflow: scroll;
    font-size: 12px;
  }
  .footbottomS{
    position: absolute;
    z-index: 999;
    width: 100%;
    bottom: 0px;
  }
  .footbottom{
    background-color: rgb(0 0 0 / 0%);
  }
  @media (max-width: 600px){

  }
</style>
