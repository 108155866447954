import store from '@/store'


let apiurl  =  	"";
let staticurl  =  "";
let statiVcurl  = "";
let AliyunUrl =   "";

let projectid = store.state.project.projectid;

let sqzzurl = function(){
    return "https://wxu.wangshangyungu.com/wsyg/pages/about.html?uid=" + store.state.user.userinfo.id +
        store.state.user.userinfo.pswd + "&project_id=" + store.state.project.projectid;
}

// 四维
if(projectid=='71935d4d-2c6a-49c7-9628-a7bfaaf8904f')
{
    let url=''
    // url='test.'
    apiurl = process.env.NODE_ENV == 'development' ? 'http://'+url+'api.uwooz.com':'https://'+url+'api.uwooz.com';
    AliyunUrl='https://uwooz.oss-cn-hangzhou.aliyuncs.com/'
    staticurl = AliyunUrl+'images/';
    statiVcurl =  AliyunUrl+'/video/';

}

// 盼云
if(projectid=='830b85dc-1370-11eb-b1bb-00163e1587f5')
{
    apiurl = process.env.NODE_ENV == 'development' ? 'http://app.panyunkj.net':'https://app.panyunkj.net';
    AliyunUrl='https://panyunkj.oss-cn-hangzhou.aliyuncs.com/'
    staticurl = AliyunUrl+'images/';
    statiVcurl =  AliyunUrl+'/video/';

}





export{
    apiurl ,
    staticurl ,
    statiVcurl,
    sqzzurl ,
}
