import request from '@/utils/request'
// 查找城市下的项目
export function projectlist(data) {
  return request({
    url: '/v2/wsyg/apartment',
    method: 'POST',
    data: data
  })
}
// 获取项目信息
export function getprojectspi(data) {
  // 加入缓存
  // var result=sessionStorage.projectInfo
  // if(result!=null&&result!='') return result

  var result= request({
    url: '/v2/wsyg/getApartment',
    method: 'POST',
    data: data
  })

  // sessionStorage.projectInfo=result
  return result

}

export function yqjsapi(data) {
  return request({
    url: '/v2/wsyg/about',
    method: 'POST',
    data: data
  })
}

export function yqhlapi(data) {
  return request({
    url: '/v2/wsyg/bonus',
    method: 'POST',
    data: data
  })
}
// 入园指南 弹窗
export function ryznapi(data) {
  return request({
    url: '/v2/wsyg/guide',
    method: 'POST',
    data: data
  })
}

// 推荐服务
export function indexserviceapi(data) {
  return request({
    url: '/v2/wsyg/homeService',
    method: 'POST',
    data: data
  })
}
// 服务分类
export function serviceCategoryApi(data) {
  return request({
    url: '/v2/wsyg/serviceCategory',
    method: 'POST',
    data: data
  })
}
// 分类下所属服务 传分类
export function serviceapi(data) {
  return request({
    url: '/v2/wsyg/service',
    method: 'POST',
    data: data
  })
}
// 广告位置
export function swiperapi(data) {
  return request({
    url: '/v2/wsyg/adPosition',
    method: 'POST',
    data: data
  })
}
// 友情链接
export function friendLinkApi(data) {
  return request({
    url: '/v2/wsyg/friendLink',
    method: 'POST',
    data: data
  })
}
