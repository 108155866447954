<template>
  <!-- 申请流程  合作流程-->
    <div class="sqlccontent wow animate__animated animate__flipInX divMa bjt3 bjtcssZbping">

      <titleDiv title="合作流程" english-title="APPLICATION PROCESS" class="tit" show="false"></titleDiv>

      <div>
        <div class="sqlc_item" v-for="(item, index) in ryzn.list" :key="index">
          <div class="sqlc_item_title">{{item.guideName}} </div>
          <div class="sqlc_item_text">{{item.guideContent}}</div>
        </div>
      </div>
    </div>

</template>

<script>
import '@/style/css/index.css';
import {ryznapi} from "@/api/index.js";
import wow from 'wowjs'
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "flowListTemplate",
  data(){
    return{
      project:this.$store.state.project,
      ryzn: {
        title:'合作流程',
        list:[],
      },
    }
  },
  props:[],
  beforeMount(){
    let key='流程listFlow';
    console.log(key)

    //this.projeSid = this.$store.state.project.projectid;//项目id
     //sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)

      ryznapi({apartmentSid : this.project.projectid}).then((res) => {
        this.ryzn.list = res.data.result;
        window.sessionStorage.setItem(key, JSON.stringify(this.ryzn.list))
      })
    }else {
      console.log("读缓存")
      this.ryzn.list = JSON.parse(window.sessionStorage.getItem(key))
    }
  },
}
</script>

<style scoped>
.sqlccontent{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sqlc_item{
  display: flex;
  margin: 30px 0;
  transition: all 1s;
}

.sqlc_item:hover{
  transform: scale(1.3,1.3);
}
.sqlc_item:nth-child(2){
  margin-left: 35px;
}

.sqlc_item:nth-child(3){
  margin-left: 70px;
}

.sqlc_item:nth-child(4){
  margin-left: 115px;
}
.sqlc_item:nth-child(5){
  margin-left: 145px;
}
.sqlc_item_title{
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
}

.sqlc_item_text{
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-left: 20px;
}

.tit{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px){
  .sqlccontent{
    flex-direction: column;
  }
  .sqlc_item:hover{
    transform: scale(0.9,0.9);
    color: #5d2e8c;
    font-weight: 600;
  }
  .sqlc_item:nth-child(2){
    margin-left: 4px;
  }

  .sqlc_item:nth-child(3){
    margin-left: 6px;
  }

  .sqlc_item:nth-child(4){
    margin-left: 8px;
  }
  .sqlc_item:nth-child(5){
    margin-left: 10px;
  }
}
</style>
