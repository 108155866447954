<template>
  <div :class="project.titleBjt===true&&isShow===true?'titleBjImg':'bigtitlebox'">
    <div class="bigtitle1">{{ title }}</div>
    <div class="bigtitle2"
         v-if="project.titleEnglishIsShow===true">{{ englishTitle }}</div>
  </div>
</template>

<script>
export default {
  name: "title",
  data(){
    return{
      project:this.$store.state.project,
      isShow:true,
    }
  },
  props:["title","englishTitle","show"],
  beforeMount(){
    if(!!this.show){
      console.log("title99999999999999999")
      this.isShow=false//给 合作流程  福利政策  用，有背景图不好看
    }
  }
}
</script>

<style scoped>
.titleBjImg{
  background-image: url(~@/assets/img/titleBjImg.png);
  background-size: cover;
  color: #ffffff;
  width: 100%;
}
.bigtitlebox{
  margin: 25px 0;
  color: #572588;
}
.bigtitle1,.bigtitle2{
  text-align: center;
}

.bigtitle1{
  font-size: 30px;
  line-height: 100px;
}

.bigtitle2{
  font-size: 15px;
}
@media (max-width: 600px){
  .bigtitlebox{
    margin: 15px 0;
  }
  .bigtitle1{
    line-height: 60px;
  }
}

</style>
