<template>
<div>
  <!--弹窗-->
  <el-dialog
      class="rytz_model"
      top='30vh'
      :title="title?title:''"
      :visible="visible?visible:false"
      :show-close='false'
      width="100%"
      center
  >
    <span>{{ content?content:'' }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="iknow" round>我 知 道 了</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
//import { yqjsapi , getprojectspi , ryznapi , yqhlapi , indexserviceapi , swiperapi } from '@/api/index.js';
import { sqzzurl , staticurl,statiVcurl } from '@/utils/baseurl'
import wow from 'wowjs'
import  {isAssetTypeAnImage,} from '@/utils/common.js'

import sidebar from '@/components/sidebar'
import SupportingService from "@/components/SupportingService.vue";
import banner from "@/components/banner.vue";
import flowListTemplate from "@/components/flowListTemplate.vue";
import gywm from "@/components/gywm.vue";
import flzc from "@/components/flzc.vue";
import elDialogVue from "@/components/elDialog.vue";

export default {
  name: "elDialog",
  data(){
    return{

    }
  },
  props:["title","visible","content"],
  methods:{
    iknow(){
      //this.visible = false;
    },
  }
}
</script>

<style scoped>

</style>
