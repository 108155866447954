
function setsession(){
    sessionStorage.state = 'true';
}

function getsession(){
    var data = sessionStorage.state;
    if(data){
        return true;
    }
    else{
        return false;
    }
}

//token
export function settoken(data){
    setsession();
    localStorage.token = data;
    return true;
}

export function gettoken(){
    var data = localStorage.token;
    if(data){
        if(getsession()){
            return data;
        }else{
            return false;
        }
    }
    else{
        return false;
    }
}

export function cleantoken(){
    localStorage.removeItem('token');
    return true;
}

//userinfo
export function setuserinfo(data){
    setsession();
    localStorage.userinfo = JSON.stringify(data);
    return true;
}

export function getuserinfo(){
    var data = localStorage.userinfo;
    if(data){
        if(getsession()){
            return JSON.parse(data);
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export function cleanuserinfo(){
    localStorage.removeItem('userinfo');
    return true;
}

//rememberpwd
export function setuserpwd(data){
    localStorage.userpwd = JSON.stringify(data);
    return true;
}

export function getuserpwd(){
    var data = localStorage.userpwd;
    if(data){
        return JSON.parse(data);
    }
    else{
        return false;
    }
}

export function cleanuserpwd(){
    localStorage.removeItem('userpwd');
    return true;
}


