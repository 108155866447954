<template>
<div>
  <!--配套服务组件(推荐) v-for='(item,index) in service'-->
  <div class="ptfwwrap divMa" v-for='(item,index) in service' :key="index">

    <titleDiv :title="item.title" :english-title="item.englishTiele"></titleDiv>

    <div class="fwxrow wow animate__animated animate__fadeInUp">
      <div class="fwx_item" v-for='(item1,index1) in item.list' :key="index1" @click="divherf(item1)">
        <img class="serviceimg_item" :src=" dateStaticurl+item1.typeImage"/>
        <div v-html="charRelace(item1.typeName)" class="fwxtext"></div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import '@/style/css/index.css';
import {staticurl} from "@/utils/baseurl";
import {indexserviceapi,serviceapi} from "@/api/index.js";
import  {strRelacebr} from '@/utils/common.js'
import wow from 'wowjs'
import {serviceCategoryApi} from "../api";
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "SupportingService",
  data(){
    return{
      project:this.$store.state.project,
      dateStaticurl:staticurl,
      service: {
          title:"",
          englishTiele:"",
          list: []
        },
    }
  },
  props:["isRecommended","bjImgHidden"],
  beforeMount(){
    let key='SupportingService'+this.isRecommended;
    console.log('服务')
     // sessionStorage.removeItem(key);//清空singlePage

    let list = JSON.parse(window.sessionStorage.getItem(key))
      if(!list){
        // !this.$store.state.project.enableSession &&
      //推荐 0=否 1=是
      let list4={}
        let list1=[]
      if(this.isRecommended==='1') {
        console.log("请求接口服务(推荐)")
        indexserviceapi({apartmentSid : this.project.projectid}).then((res) => {
          list4={
            'title':"配套服务",
            'englishTiele':"SUPPORTING SERVICES",
            'list':res.data.result
          }
          this.service=[list4]
          window.sessionStorage.setItem(key, JSON.stringify(this.service))
        })
      }else {
        serviceCategoryApi({apartmentSid : this.project.projectid}).then((res) => {
          let list=res.data.result//分类

           for(let i=0;i<list.length;i++){
             console.log("请求接口服务(分类)")
             serviceapi({apartmentSid : this.project.projectid,categorySid:list[i].categorySid}).then((res) => {
               list4={
                 'title':list[i].categoryName,
                 'englishTiele':"SUPPORTING SERVICES",
                 'list':res.data.result
               }
               if(i==0){
                 this.service=[list4]
               }else {
                 this.service.push(list4)
               }
               window.sessionStorage.setItem(key, JSON.stringify(this.service))
             })
           }
        })
      }

    }else {
      console.log("读缓存")
      this.service = JSON.parse(window.sessionStorage.getItem(key))
    }
  },
  mounted(){
    this.$nextTick(() => {
      new wow.WOW().init()
    })
  },
  methods:{
      charRelace:function(str)
      {
          return strRelacebr(str)
      },

    divherf:function(item){
      console.log("服务跳转")
      if(!!item.serviceUrl){
        window.open(item.serviceUrl, '_blank')//_self
      }else {
        let key="SupportingServiceDetails"+item.typeNo
        window.sessionStorage.setItem(key, JSON.stringify(item))
        this.$router.push({
          path: '/SupportingServiceDetails?typeNo='+item.typeNo
        })
      }
    }
  }
}
</script>

<style scoped>
.ptfwwrap{
  /*width: 100%;*/
  background-image: url("../assets/images/s.png");
  background-repeat: no-repeat;
  background-size:auto 60%;
  background-position: 99% center;
}
.fwxrow{
  display: flex;
    justify-content:  space-between;
  overflow-x: scroll;
  flex-wrap: wrap;
  padding-top: 20px;
}
.fwx_item{
  margin: 0 30px;
    text-align: center;

}
.serviceimg_item{
  width: 120px;
  height: 120px;
    max-height: 150px;
    max-width: 150px;
    min-height: 100px;
    min-width: 100px;
  transition: all 0.6s;
}

.serviceimg_item:hover{
  box-shadow: 1px 1px 34px rgba(0,0,0,0.4);
  transform: scale(1.2,1.2);
}
.fwxtext{
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
    /*换行  /n */
    white-space: pre-wrap;
  /*margin-bottom: 140px;*/
  color: #333333;
}

.flzcwrap{
  width: 100%;
  position: relative;
  background-image: url("../assets/images/w.png");
  background-repeat: no-repeat;
  background-size:auto 60%;
  background-position: 20px center;
}
.flzccontent{
  width: 1050px;
  margin: 0 auto;
  margin-top: 220px;
}
.flzcbox{
  width: 50%;
}
.indexbox{
  width: 100%;
  overflow-x: hidden;
}

.swiper-container {
  width: 100%;
  /* height: 90%; */
  height: auto;
  position: relative;
  overflow: revert;
}

.swiperbgimg{
  width: 100%;
  height: 100%;
  /* background-image: url(""); */
  background-size: 100% 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperimg{
  width: 100%;
  height: 100%;
}

.sqlcwrap{
  width: 100%;
  background-image: url("../assets/images/p.png");
  background-repeat: no-repeat;
  background-size:auto 90%;
  background-position: 20px bottom;
}

.sqlccontent{
  width: 1050px;
  margin: 0 auto;
  display: flex;
}

.sqlc_left{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sqlc_left_title:nth-child(1){
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
  line-height: 60px;
}

.sqlc_left_title:nth-child(2){
  font-size: 15px;
  color: rgba(93, 46, 140, 1);
}

.sqlc_right{
  margin-left: 110px;
}

.sqlc_item{
  display: flex;
  margin: 30px 0;
  transition: all 1s;
}

.sqlc_item:hover{
  transform: scale(1.2,1.2);
}

.sqlc_item_title{
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
}

.sqlc_item_text{
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-left: 20px;
}

.sqlc_item:nth-child(2){
  margin-left: 35px;
}

.sqlc_item:nth-child(3){
  margin-left: 70px;
}

.sqlc_item:nth-child(4){
  margin-left: 115px;
}
.sqlc_item:nth-child(5){
  margin-left: 145px;
}

.uswrap{
  width: 100%;
  background-image: url("../assets/images/A.png");
  background-repeat: no-repeat;
  background-size:auto 60%;
  background-position: 99% center;
}

.uscontent{
  width: 1050px;
  margin: 0 auto;
  margin-top: 35px;
}

.ustitle{
  text-align: center;
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
  line-height: 60px;
}

.ustitle2{
  font-size: 15px;
  color: rgba(93, 46, 140, 1);
  text-align: center;
}

.us_item{
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.usimg{
  width: 50%;
}

.ustext{
  line-height: 30px;
  box-sizing: border-box;
  padding: 30px 40px;
  font-size: 14px;
}

.ustext1,.ustext2{
  letter-spacing: 1px;
  text-indent: 30px;
}

.flzcwrap{
  width: 100%;
  position: relative;
  background-image: url("../assets/images/w.png");
  background-repeat: no-repeat;
  background-size:auto 60%;
  background-position: 20px center;
}

.flzccontent{
  width: 1050px;
  margin: 0 auto;
  margin-top: 220px;
}

.flzcbox{
  width: 50%;
}

.flzc_title{
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.flzc_text{
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 25px;
  box-sizing: border-box;
  padding-right: 20px;
}

.flzc_title1{
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
  margin-top: 15px;
}

.flzc_title2{
  font-size: 15px;
  color: rgba(93, 46, 140, 1);
  margin-bottom: 15px;
}
.flzcimg{
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media (max-width: 600px){
  .fwxrow{
    overflow-x: scroll;
    /*margin-top: 30px;*/
    justify-content: center;
    flex-wrap: wrap;
      display: flex;
  }
  .fwx_item{
    margin: 0 50px;
  }
  .serviceimg_item{
    width: 100px;
    height: 100px;
  }

}
</style>
