import { render, staticRenderFns } from "./indexfloat.vue?vue&type=template&id=404d6b9b&scoped=true&"
import script from "./indexfloat.vue?vue&type=script&lang=js&"
export * from "./indexfloat.vue?vue&type=script&lang=js&"
import style0 from "./indexfloat.vue?vue&type=style&index=0&id=404d6b9b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "404d6b9b",
  null
  
)

export default component.exports