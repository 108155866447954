/**
 * commonUntil.js
 * Created by 韩桂光 on 2016/11/17.
 */

// window.sessionStorage  window.localStorage

var expireTime=60*60*24*30;  // 秒   有效期时间

/******************************************************window.localStorage************************************/
/**
 * 存缓存  window.localStorage
 * @param key 键值  value 内容  userExpireTime 自定义超时有效期
 * @return
 */
export function  saveLocalSession(key,value,userExpireTime)
{

    var data=value;
    if( typeof value!= "string")
        data=JSON.stringify(value);  // 把数据转为　字符串存储

    window.localStorage[key]=data;


    // 自动存储一个时间字段
    var nowTime=new Date();
    var nowTimeStr= getSmpFormatDate(nowTime,true);

    var keyTime=key+'Time';
    window.localStorage[keyTime]=nowTimeStr;


    // 存储有效期 字段
    if(userExpireTime==undefined){
        userExpireTime= expireTime;			// 使用系统默认
    }
    window.localStorage['userExpireTime']=userExpireTime;


}


export function  getLocalSession(key)
{
    var value= window.localStorage[key];  // 取出来字符串

    if(value&&value!="")
    {

        // 取出来 用户的自定义超时时长
        var userExpireTime=window.localStorage['userExpireTime'];		// 取出超时 定义
        if(userExpireTime==""){
            userExpireTime=expireTime;				// 使用系统默认

        }

        //判断有效期
        var keyTime=key+'Time';
        var valueTime=window.localStorage[keyTime];		// 取出日期字符串

        var difSecond=getDateDiff(valueTime);

        if(difSecond>userExpireTime)		//超时
        {
            return "";
        }

    }
    else {
        // 没有存储
        return "";
    }

    return value;


}
/******************************************************window.localStorage************************************/

/******************************************************window.sessionStorage**********************************/
/**
 * 存缓存  window.sessionStorage
 * @param key 键值  value 内容  userExpireTime 自定义超时有效期
 * @return
 */
export function  saveSession(key,value,userExpireTime)
{
	
    var data=value;
    if( typeof value!= "string")
        data=JSON.stringify(value);  // 把数据转为　字符串存储

    window.sessionStorage[key]=data;


    // 自动存储一个时间字段
    var nowTime=new Date();
    var nowTimeStr= getSmpFormatDate(nowTime,true);

    var keyTime=key+'Time';
    window.sessionStorage[keyTime]=nowTimeStr;


    // 存储有效期 字段
    if(userExpireTime==undefined){
        userExpireTime= expireTime;			// 使用系统默认
    }
    window.sessionStorage['userExpireTime']=userExpireTime;


}


export function  getSession(key)
{
    var value= window.sessionStorage[key];  // 取出来字符串

    if(value&&value!="")
    {

        // 取出来 用户的自定义超时时长
        var userExpireTime=window.sessionStorage['userExpireTime'];		// 取出超时 定义
        if(userExpireTime==""){
            userExpireTime=expireTime;				// 使用系统默认

        }

        //判断有效期
        var keyTime=key+'Time';
        var valueTime=window.sessionStorage[keyTime];		// 取出日期字符串

        var difSecond=getDateDiff(valueTime);

        if(difSecond>userExpireTime)		//超时
        {
            return "";
        }

    }
    else {
        // 没有存储
        return "";
    }

    return value;


}
/******************************************************window.sessionStorage**********************************/


/**
 * 计算传入时间和当前时间差
 * @param d 时间 格式：2010-04-10 10:22:36
 * @return
 */
export function getDateDiff(d,now){
    if(now==undefined){
        now=new Date().getTime();
    }else{
        now=Date.parse(now.replace(/-/g,'/').replace(/：/g,":"));
    }
    var diffValue = now - Date.parse(d.replace(/-/g,'/').replace(/：/g,":")); 		// 毫秒

    return    diffValue/1000;		// 转为秒 返回
}





/**
 *转换日期对象为日期字符串
 * @param date 日期对象
 * @param isFull 是否为完整的日期数据,
 *               为true时, 格式如"2000-03-05 01:05:04"
 *               为false时, 格式如 "2000-03-05"
 * @return 符合要求的日期字符串
 */
export function getSmpFormatDate(date,isFull){
    var pattern = "";
    if (isFull==true||isFull==undefined) {
        pattern = "yyyy-MM-dd hh:mm:ss";
    } else {
        pattern = "yyyy-MM-dd";
    }
    return getFormatDate(date,pattern);
}


/**
 *转换日期对象为日期字符串
 * @param l long值
 * @param pattern 格式字符串,例如：yyyy-MM-dd hh:mm:ss
 * @return 符合要求的日期字符串
 */
export function getFormatDate(date,pattern){
    if(date==undefined){
        date=new Date();
    }
    if(pattern==undefined){
        pattern="yyyy-MM-dd hh:mm:ss";
    }
    return date.format(pattern);
}



/**
 * 时间对象的格式化;  为系统JS 增加format 函数
 */
Date.prototype.format =  function(format) {
    /*
     * eg:format="yyyy-MM-dd hh:mm:ss";
     */
    var o = {
        "M+" : this.getMonth() + 1, // month
        "d+" : this.getDate(), // day
        "h+" : this.getHours(), // hour
        "m+" : this.getMinutes(), // minute
        "s+" : this.getSeconds(), // second
        "q+" : Math.floor((this.getMonth() + 3) / 3), // quarter
        "S+" : this.getMilliseconds()
        // millisecond
    }

    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4
            - RegExp.$1.length));
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            var formatStr="";
            for(var i=1;i<=RegExp.$1.length;i++){
                formatStr+="0";
            }

            var replaceStr="";
            if(RegExp.$1.length == 1){
                replaceStr=o[k];
            }else{
                formatStr=formatStr+o[k];
                var index=("" + o[k]).length;
                formatStr=formatStr.substr(index);
                replaceStr=formatStr;
            }
            format = format.replace(RegExp.$1, replaceStr);
        }
    }
    return format;
}


//获取网站根目录
export function getRootPath_dc() {
    var pathName = window.location.pathname.substring(1);
    var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
    if (webName == "") {
        return window.location.protocol + '//' + window.location.host;
    }
    else {
        return window.location.protocol + '//' + window.location.host + '/' + webName;
    }
}


/////////////////////////////////////
var sessionSid='';
var accessToken='';
var checkcode='AIzaSyDgDTWUqhXyDx_NayRsGNHBcYTjiVyB1Mw';
//ajax封装
export function Ajaxmy(mycache, myasync, mydataType, mytype, myurl, mydata, mycallback) {
    mydata=JSON.stringify(mydata);
    console.log(mydata)
    
    $.ajax({
        cache: mycache,//是否缓存页面
        async: myasync,//同步还是异步
        dataType: mydataType,
        type: mytype,
        url: myurl,
        data: mydata,
        success: mycallback,
        beforeSend:  function(request) {
               // request.setRequestHeader("accessToken", accessToken);
                //request.setRequestHeader("sessionSid", sessionSid);
              request.setRequestHeader("check-code", checkcode);
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
              },
        error:  function (message) {
              //只提示一次
              console.log(message);
              alert('服务访问出错：' + message.status + message.statusText);
      }
    });
}



Date.prototype.format = function(format)
{
var o = {
"M+" : this.getMonth()+1, //month
"d+" : this.getDate(), //day
"h+" : this.getHours(), //hour
"m+" : this.getMinutes(), //minute
"s+" : this.getSeconds(), //second
"q+" : Math.floor((this.getMonth()+3)/3), //quarter
"S" : this.getMilliseconds() //millisecond
}
if(/(y+)/.test(format)) format=format.replace(RegExp.$1,
(this.getFullYear()+"").substr(4- RegExp.$1.length));
for(var k in o)if(new RegExp("("+ k +")").test(format))
format = format.replace(RegExp.$1,
RegExp.$1.length==1? o[k] :
("00"+ o[k]).substr((""+ o[k]).length));
return format;
}