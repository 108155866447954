
let state={}

// 盼云
// state = {
//     projectid: '830b85dc-1370-11eb-b1bb-00163e1587f5',
//     address: '杭州',
//     addressinfo: '',
//     servedata: true,
//     projectName: '盼云科技',
//     projectEnglishName: 'P A N Y U N',
//     projectDesc: '针对跨境行业的服务平台',
// titleEnglishIsShow:false,
//     enableSession:false,
// }

// 四维
 state = {
    projectid: '71935d4d-2c6a-49c7-9628-a7bfaaf8904f',
    address: '杭州',
    addressinfo: '',
    servedata: true,
     projectName: '四维云旅',
     projectEnglishName: 'S I W E I Y U N L V',
     projectDesc: '针对旅游行业的大型综合云产品及服务平台',
     titleEnglishIsShow:false,
     titleBjt:false,
     enableSession:true,     // 开启缓存
}




const mutations = {
    set_projectid(state,data){

    },
}

export default {
  namespaced: true,
  state,
  mutations,
}
