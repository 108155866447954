<template>
  <!--福利政策-->
  <div class="flzcwrap divMa bjt4 bjtcssZbping">
    <div class="flzccontent wow animate__animated animate__fadeInLeft">
      <div class="flzcbox">
        <div v-for="(item,index) in yqhl.list" :key='index + new Date()'>
          <div class="flzc_title" v-show="index < 2">{{item.bonusName}}</div>
          <div class="flzc_text" v-show="index < 2">
            {{item.bonusContent}}
          </div>
        </div>

        <titleDiv title="福利政策" english-title="WELFARE POLICY" class="flzctit" show="false"></titleDiv>

        <div v-for="(item,index) in yqhl.list" :key='index'>
          <div class="flzc_title" v-if="index >= 2">{{item.bonusName}}</div>
          <div class="flzc_text" v-if="index >= 2">
            {{item.bonusContent}}
          </div>
        </div>
      </div>
    </div>
    <img class="flzcimg wow animate__animated animate__fadeInRight" :src="staticurlSrc+yqhl.img"/>
  </div>
</template>

<script>
import '@/style/css/index.css';
import {staticurl} from "@/utils/baseurl";
import {yqhlapi, yqjsapi} from "@/api";
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "flzc",
  data() {
    return {
      project:this.$store.state.project,
      staticurlSrc:staticurl,
      yqhl:{
        list:[],
        img: '',
      },
    }
  },
  props:["titleEnglish","bjImgHidden"],
  beforeMount(){
    let key='flzc';
    console.log(key)
    //let projeSid = this.$store.state.project.projectid;//项目id
   //sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.$store.state.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)

      yqhlapi({apartmentSid : this.project.projectid}).then((res) => {
        this.yqhl.list = res.data.result
        this.yqhl.img =  res.data.result[0].bonusLogo;
        window.sessionStorage.setItem(key, JSON.stringify(this.yqhl.list))
      })

    }else {
      console.log("读缓存")
      this.yqhl.list = JSON.parse(window.sessionStorage.getItem(key))
      this.yqhl.img= this.yqhl.list[0].bonusLogo;
    }
  },
}
</script>

<style scoped>
.flzcwrap{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flzccontent{
  width: 100%;
}

.flzcbox{
  width: 100%;
}

.flzc_title{
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.flzc_text{
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 25px;
  box-sizing: border-box;
  padding-right: 20px;
}
.flzcimg{
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
}
.flzctit{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px 0;
}
.bigtitle1{
  line-height: 40px;
}
@media (max-width: 600px){
  .flzcwrap{
    align-items: center;
    flex-direction: column;
  }
  .flzc_text{
    padding-right: 0px;
  }
}
</style>
