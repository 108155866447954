<template>
<!--  顶部组件-->
    <div class="headbox">
      <!-- <span class="selectaddress">
        你好，杭州  漂浮效果版本
      </span>
      <img class="logoimg" src="@/assets/img/wsyglogo.png" />
      <span class="selectaddress">
        盼云跨境（中国杭州）电子商务产业园
      </span> -->
      <span class="tabbar">
        <!-- <img class='logo2' src='@/assets/images/logo2.png' /> -->
<!--        <span :class=" tabbar_active == 0 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(0)">首页</span>-->
<!--        <span :class=" tabbar_active == 1 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(1)">产品核心</span>-->
<!--        <span :class=" tabbar_active == 2 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(2)">最新资讯</span>-->
<!--        <span :class=" tabbar_active == 3 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(3)">常见问题</span>-->
      </span>
      <span class="userbox" v-if="!this.$store.state.user.token">
        <span class="loginbtn" @click="login" v-if="1==0">登录</span>
        <span class="resistbtn" @click="regist" v-if="1==0">注册</span>
      </span>
      <span class="userbox" v-else>
        <span class="userinfobtn" @click="userinfo">
          <!-- <img class="headimg" src="https://org.modao.cc/uploads4/images/5553/55534051/v2_qifjoj.png"/> -->
          <!-- 个人中心 -->
          {{$store.state.user.userinfo.userName||$store.state.user.userinfo.nickName||'个人中心'}}
        </span>
        <span class="loginoutbtn" @click="loginout">注销</span>
      </span>
    </div>
</template>

<script>
import wow from "wowjs";

export default {
    data() {
        return {
          path: ['/','/zoujinyq','/yqdt','/question'],
          tabbar_active: 0,
          headList:[
            {id:'first',name:'first',title:'首页',path:'/'},
            {id:'zoujinyqfloat',name:'zoujinyqfloat',title:'服务内容',path:'/zoujinyqfloat'},
            {id:'yqdtfloat',name:'yqdtfloat',title:'最新资讯',path:'/yqdtfloat'},
            // {id:'question',name:'question',title:'常见问题',path:'/question'},
            {id:'about',name:'about',title:'关于我们',path:'/about'},
            {id:'question',name:'question',title:'联系我们',path:'/contact'},
          ]
        }
    },
    methods:{
        login(){
          this.$router.push({
            path: '/login'
          })
        },
        regist(){
          this.$router.push({
            path: '/regist'
          })
        },
        userinfo(){
          // this.$router.push({
          //   path: '/userinfo'
          // })
        },
        loginout(){
          //退出登录
          this.$store.commit('user/set_loginout')
        },
        push(index){
          this.$router.push({
            path: this.path[index]
          })
          this.tabbar_active = index;
        },
      createFloat (id,name,title,index,path) {
        //获取元素
        // var div1 = document.getElementById('div1');
        // 创建一个新的 div 元素
        let newDiv = document.createElement(name);
        // // 改变 外联样式
         newDiv.setAttribute('tabbar_active',index)
        newDiv.setAttribute('id',id)
        newDiv.setAttribute('name',name)
        newDiv.setAttribute('path',path)    // 新增一个属性 后期使用
        // newDiv.setAttribute('tab','')    // 新增一个属性 后期使用
        // 内嵌样式
        newDiv.style.cssText="width: 150px;\n" +
                "            height: 150px;\n" +
                "            padding: 0;\n" +
                "            margin: 0;\n" +
                "            position: fixed;\n" +
                "            left: 0;" +
                "            top: 0;" +
                "border-radius:50%;" +
                "display: flex;\n" +
                "    justify-content: center;/*垂直居中*/\n" +
                "    align-items: center;/*水平居中*/" +
                " z-index:999;"
        // 给它一些内容
        let newContentDiv = document.createElement(id+'div');
         let newContent = document.createTextNode(title);
        newContentDiv.appendChild(newContent)
        newContentDiv.style.cssText="z-index:999;display: flex;justify-content: center;align-items: center;border-radius:50%;"

        //添加 img
        var img = document.createElement("img");
        //设置 img 属性，如 id
        img.setAttribute("id", name+"Img");
        img.style.cssText="width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0;"
        //设置 img 图片地址
        img.src = "../assets/images/paopao.png";

        // 添加文本节点 到这个新的 div 元素
        newDiv.appendChild(img);
        newDiv.appendChild(newContentDiv);



        // 将这个新的元素和它的文本添加到 DOM 中
       // let currentDiv = document.getElementById(name);
       //  document.body.insertBefore(newDiv, currentDiv);
        //插入到最前面
        // document.body.insertBefore(div, document.body.firstElementChild);
        // 追加 div 到body
        document.body.appendChild(newDiv);

        var w = document.documentElement.clientWidth;
        var h = document.documentElement.clientHeight;
        var gox = 1+index;
        var goy = 1;
        var a=0;
        //广告漂浮
        function move() {
          var x = newDiv.offsetLeft;
          var y = newDiv.offsetTop;
          if(a==0)
          {
          }
          var newtop=0;
          var newleft=0;
          if (x > w - 200 || x < 0) gox = -gox;
       newleft= x + (10 * gox) + 'px';
          if (y > h - 200 || y < 0) goy = -goy;
          newtop= y + (10 * goy) + 'px';

          newDiv.style.left=newleft
          newDiv.style.top=newtop
          // newDiv.animate({
          //   left:newleft,
          //   top:newtop
          // },"fast"); // 动画效果


        }
        //漂浮定时器
        var timer = setInterval(move, 280);
        //移入移出时广告的浮动
        newDiv.onmouseover = function () {
          clearInterval(timer);
        }
        newDiv.onmouseout = function () {
          timer = setInterval(move, 280);
        }
        //点击一次跳转到另一个页面，点击两次广告消失
        var that=this
        newDiv.onclick = function() {
          // if (a === 0) {
          //   var neww = window.open('_blank');
          //   neww.location = "http://www.baidu.com";
          //   a++;
          // } else {
          //   this.style.display = "none";
          // }
          // 根据ID 跳转去不同的页面
          that.$router.push({
            path: path
          })
          that.tabbar_active = index;
        }

      }
    },
    mounted(){
      console.log(this.path.indexOf(this.$router.currentRoute.path));
      if(this.path.indexOf(this.$router.currentRoute.path) == -1){
        this.tabbar_active = -1;
      }else{
        this.tabbar_active = this.path.indexOf(this.$router.currentRoute.path);
      }

      var that=this
      //把动态获取ID的操作放到this.$nextTick的回调中执行即可
      // 使用去全局缓存 做判断  免得多次生成
    if(sessionStorage.float!=1)
    {
      for(var i=0;i<this.headList.length;i++)
      {
        setTimeout(1000)
        var row=this.headList[i]
        that.createFloat(row.id,row.name,row.title,i,row.path)
      }
      sessionStorage.float=1
    }

    var that=this
    setTimeout(function () {
// 隐藏当前页面对应的 tab
      for(var i=0;i<that.headList.length;i++)
      {
        var row=that.headList[i]
        // that.createFloat(row.id,row.name,row.title,i,row.path)
        var url=  window.location.href
        if(url.indexOf('#')>-1)
        {
          var currentTabDiv=document.getElementById(row.id)
          // currentTabDiv.style.removeProperty('display')
          currentTabDiv.style.display='flex'
          var modulePath=  url.split('#')[1] //获取 # 后面的路径名称
          if(modulePath==row.path)
          {// 当前tab 则隐藏
            currentTabDiv.style.display='none'
          }

        }

      }

    },1000)



      this.$nextTick(() => {
      })

        // 页面刷新 恢复
        window.onbeforeunload = function(){
            sessionStorage.float=0
        }

    }
}
</script>

<style scoped>

  .headbox{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: fixed; */
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }

  .selectaddress{
    /* background-color: rgba(245, 80, 59, 1); */
    margin: 20px;
  }

  .logoimg{
    width: 40px;
    /* height: 50px; */
  }

  .tabbar{
    position: relative;
    margin-right: 300px;
    display: flex;
    align-items: center;
  }

  .tabbaritem{
    margin: 0 20px;
    cursor: pointer;
    color: #FFFFFF;
  }

  .userbox{
    position: relative;
    margin: 40px 0px;
  }

  .tabbaritem_active{
    /* color: #EB7A67; */
    border-bottom: solid 2px rgb(54, 178, 211);
    padding: 4px 0;
  }

  .resistbtn,.loginbtn,.userinfobtn,.loginoutbtn{
    /* display: inline-block; */
    padding: 5px 7px;
    /* background-color: #FFFFFF; */
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
  }

  .loginbtn,.userinfobtn{
    /* border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; */
    margin-right: 15px;
  }

  .resistbtn,.loginoutbtn{
    /* border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; */
  }

  .userinfobtn{
    padding-left: 35px;
    position: relative;
  }

  .headimg{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 5px;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
  }

  .logo2{
    width: 120px;
  }

  .userbox,.logo2{
    margin-right: 120px;
  }

</style>
