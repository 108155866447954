<template>
<!--  底部组件-->
  <div class="footbox">
    <div class="foottop">
      <div class="footcol">
        <!-- <div class="foot_text logo"><img src="@/assets/images/logo.png" /></div> -->
        <div class="foot_text gsname">
          {{ APinfo.apartmentShortname }}
        </div>
      </div>
      <div class="footcol">
        <div class="foot_text">联系地址:{{APinfo.address}}</div>
        <div class="foot_text">联系电话:{{ APinfo.telephone }}</div>
        <div class="foot_text">商务合作:{{ APinfo.telephone1 }}</div>
        <div class="foot_text">联系邮箱:{{ APinfo.companyEmail }}</div>
      </div>
      <div class="footcol">
        <div>
          <img :src="staticurlSrc+APinfo.pwQrcode" class="ewm" />
          <div class="ewm_title">  扫扫了解更多</div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import { staticurl } from "@/utils/baseurl";
import { saveSession } from "@/utils/windowStorage";
import { getfootinfo } from "@/api/foot";
import bottom from "@/components/bottom";

export default {
  components: {
    bottom,
  },
  data() {
    return {
      staticurlSrc:staticurl,
      APinfo:{apartmentShortname:""},
    };
  },
  beforeMount() {
    let key='getInfo';
    console.log(key)
    let projectid = this.$store.state.project.projectid;
    // sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    // 缓存的控制 移到 全局拦截层 处理
    getfootinfo({ apartmentSid: projectid }).then((res) => {
      this.APinfo=res.data.result[0];
      // window.sessionStorage.setItem(key, JSON.stringify(res))
      saveSession(key, JSON.stringify(res))
    });

    // if(!(this.$store.state.project.enableSession &&!!singlePageQ)){
    //   //判断缓存,不存在
    //   console.log("请求接口"+key)
    //
    //   getfootinfo({ apartmentSid: projectid }).then((res) => {
    //      this.APinfo=res.data.result[0];
    //     window.sessionStorage.setItem(key, JSON.stringify(this.APinfo))
    //   });
    // }else {
    //   console.log("读缓存")
    //   this.APinfo = JSON.parse(window.sessionStorage.getItem(key))
    // }
  },
};
</script>

<style scoped>
.footbox {
  min-height: 110px;
  /*max-height: 110px;*/
  width: 100%;
  background-image: url("../assets/images/footbg.jpg");
  background-size: cover;
}

.foottop {
  justify-content: space-around;
  padding: 3px 0 3px 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  position: relative;
  flex-direction: row;
}

.footcol {
  color: #ffffff;
}

.footcol:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin-right: 100px;*/
  letter-spacing: 2px;
}

.logo {
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.logo img {
  width: 220px;
}

.gsname {
  line-height: 50px;
  /*color: #000000;*/
  margin-bottom: 20px;
  font-size: 30px;
}

.footcol:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin-right: 600px;*/
  letter-spacing: 2px;
}

.footcol:nth-child(2) .foot_text {
  font-size: 12px;
  line-height: 30px;
}

.footcol:nth-child(2) .foot_text:nth-child(1) {
  /*line-height: 10px;*/
}

.footcol:last-child {
  height: 100%;
  /*position: absolute;*/
  /*right: 190px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ewm {
  width: 100px;
  height: 100px;
}

.ewm_title {
  /*line-height: 30px;*/
  letter-spacing: 2px;
  font-size: 12px;
  text-align: center;
}

.footbottom {
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  /*min-height: 90px;*/
  text-align: center;
  font-size: 12px;
}
.footbottomS{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.bahgs,
.bah {
  /*line-height: 40px;*/
  padding: 0 20px 0 20px;
}
@media (max-width: 600px){
  .foottop {
    justify-content: flex-start;
    padding: 3px 0 3px 0;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5px;
  }
  .footcol:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 2px;
    margin-right: 0px;
  }
  .footcol:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 2px;
    margin-right: 0px;
  }
  .footcol:last-child {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: initial;
    right: 0px;
  }
}
</style>
