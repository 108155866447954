<template>
<!--  banner组件-->
  <div class="bannerW">
    <div class="swiper-container" v-if="swiper.imgs.length>0">
      <div class="swiperbgimg"></div>
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in swiper.imgs"
             :key="index"
        >
          <img class="swiperimg" :src="dateStaticurl+item.picture" />
        </div>
      </div>
    </div>
    <div class="banner2" v-if="showTitle=='true'">
      <div class="font1">{{ title }}</div>
      <div class="font2">{{ englishName }}</div>
      <div class="font2 font3">{{ content }}</div>
    </div>
  </div>

</template>

<script>
import {staticurl} from "@/utils/baseurl";
import {swiperapi} from "@/api/zjyqapi";
import Swiper from "swiper";

export default {
  name: "banner",
  data() {
    return {
      projeSid:'',
      dateStaticurl:'',
      swiper: {
        imgs: [],
      },
      APinfo:{},
    }
  },
  props:["positionSid","title","englishName","content","showTitle"],
  beforeMount(){

    let key='banner'+this.positionSid;
    console.log(key)
    this.dateStaticurl = staticurl;
    this.projeSid = this.$store.state.project.projectid;//项目id
    // sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.$store.state.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)
      swiperapi({apartmentSid : this.projeSid, positionSid: this.positionSid }).then((res) => {
        this.swiper.imgs=res.data.result
        window.sessionStorage.setItem(key, JSON.stringify(this.swiper.imgs))
      })
    }else {
      console.log("读缓存")
      this.swiper.imgs = JSON.parse(window.sessionStorage.getItem(key))
    }


  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      mousewheelControl: true,
      allowSlidePrev: false,
      allowSlideNext: false,
      autoplay: {
        delay: 2000,
      },
      on: {
        init: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[0].classList;
          // console.log(slide)
          // slide.add('ani-slide');
        },
        transitionStart: function (swiper) {
          // for(let i = 0; i < swiper.slides.length; i++){
          //   	let slide = swiper.slides[i].classList;
          //     slide.remove('ani-slide');
          // }
        },
        transitionEnd: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[swiper.activeIndex].classList;
          // slide.add('ani-slide');
        },
      },
    });
    // this.$nextTick(() => {
    //   new wow.WOW().init();
    // });
    //
    // // 页面增加全局点击
    // window.addEventListener("click", this.hideChat);
  },
}
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: revert;
}
.swiperbgimg {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
}
.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiperimg {
  width: 100%;
  height: 100%;
}

.bannerW{
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner2{
  z-index: 888;
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.font1{
  font-size: 48px;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 700;
  color: #fff;
  opacity: 1;
}
.font1:before,.font1:after{
  content: "";
  display: inline-block;
  width: 80px;
  height: 4px;
  vertical-align: middle;
  background: #fff;
  opacity: .3;
}
.font1:before{
  margin-right: 32px;
}
.font1:after{
  margin-left: 32px;
}
.font2{
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  opacity: 1;
}
.font3{
  margin-top: 60px;
  font-family: PingFang SC;
  line-height: 28px;
}

@media (max-width: 600px){
  .swiperimg {
    height: 211px;
  }
  .font1{
    font-size: 28px;
  }
  .font1:before,.font1:after{
    width: 50px;
    height: 2px;
  }
  .font1:before{
    margin-right: 12px;
  }
  .font1:after{
    margin-left: 12px;
  }
  .font2{
    font-size: 12px;
  }
  .font3{
    margin-top: 20px;
    line-height: 18px;
  }
}
</style>
