<template>
  <div>
    <div class="sidebarwrap" v-if="show">
      <div class="sideitem" v-if="enableChat==1" @click.stop="onClickChat($event)">
        <div>在线</div>
        <div>客服</div>
        <div class="sidebar_chat" v-if="showChat">
          <button
            class="sidebar_chat_btn"
            type=""
            @click.stop="onClickChatAction($event)"
          >
            X
          </button>
          <iframe
            style="width: 100%; height: 100%"
            class="sidebar_chat_main"
            allow="microphone"
            width="350"
            height="430"
            :src="chatUrl"
          >
          </iframe>
        </div>
      </div>
      <div class="sideitem">
        <div>客服</div>
        <div>一号</div>
        <div class="sidebar_card">
          <div><img class="sidebarCardImg" :src="staticurlSrc+APinfo.alipayLdQrcode" /></div>
        </div>
      </div>
      <div class="sideitem">
        <div>客服</div>
        <div>二号</div>
        <div class="sidebar_card">
          <div>
            <img class="sidebarCardImg" :src="staticurlSrc+APinfo.alipayLdQrcode" />
          </div>
        </div>
      </div>
      <div class="sideitem" @click="top">
        <div><span class="el-icon-arrow-up toplogo"></span></div>
        <div>TOP</div>
      </div>
      <div class="sideitem">
        <div @click="sq">收起</div>
      </div>
    </div>
    <img
      v-else
      class="sidebarsq sidebarsqwrap"
      src="../assets/images/sidebarsq.png"
      @click="zk"
    />
  </div>
</template>

<script>
  import { staticurl } from "@/utils/baseurl";
import { saveSession } from "@/utils/windowStorage";
  import { getfootinfo,getDcitionaryList } from "@/api/foot";

export default {
  data() {
    return {
      staticurlSrc:staticurl,
      APinfo:[],
      show: true,
      showChat: false,
      enableChat:true,
      chatUrl:"",
    };
  },
  methods: {
    top() {
      let top = document.documentElement.scrollTop|| window.pageYOffset || document.body.scrollTop;
      // alert(document.documentElement.scrollTop)//微信内置浏览器  值=0  普通浏览器 有值
      // alert(window.pageYOffset)//微信内置浏览器  值=0  普通浏览器 值=0
      // alert(document.body.scrollTop)//普通浏览器 值=0  微信内置浏览器 有值

      document.documentElement.scrollTop = top;
      window.pageYOffset = top;
      document.body.scrollTop = top;

      let time = setInterval(() => {
        if (top > 0) {

            document.documentElement.scrollTop = top - 1;
            window.pageYOffset = top - 1;
            document.body.scrollTop = top - 1;
            top = top - 20;

         } else {
          clearInterval(time);
         }
       }, 1);
    },
    sq() {
      this.show = false;
    },
    zk() {
      this.show = true;
    },
    onClickChat(e) {
      let pWidth = document.documentElement.clientWidth;
      if(pWidth<600){
        //window.open(this.chatUrl,'_blank')
        window.open(this.chatUrl,'_self')
      }else {
        this.showChat = !this.showChat;
      }
    },
    onClickChatAction(e) {
      this.hideChat(e)
      console.log(this.showChat);
      e.stopPropagation();

    },
    hideChat(e){
      this.showChat = false;
    }
    ,
    //鼠标进入的事件。
    onEnterTd(e) {
      console.log(e);
    },
    //鼠标离开的事件。
    onLeaveTd(e) {},
    forCofing(list){
      if(list===undefined){
        return
      }
      for (var i = 0; i < list.length; i++) {
        console.log("forCofing"+i)
        switch (list[i].dictionaryKey) {
          case 'WXVERSION':

            break;
          case 'UPDATEDICLIMIT':

            break;

          case 'PRICE_IOS_GZH':
            break;
          case 'PURCHASE_IOS_GZH':
            break;
          case 'DISPLAYSUBSCRIBETEXT':
            break;
          case 'CHAT_COMPUTE':
            break;
          case 'INDEXZBDIV':
            break
          case 'FAKEINDEXPAGE':
            break
          case 'MAIN':
            break;
          case 'HEALTH_AIDE':
            //云客服 是否启用
            this.enableChat = list[i].dictionaryValue;
            break;
          case 'HEALTH_AIDE_ICON':
            //云客服ICO
            break;
          case 'HEALTH_AIDE_URL':
            //云客服url
            this.chatUrl = list[i].dictionaryValue;
            break;

        }
      }
    }
  },
  beforeMount() {

    let key='getInfo';
    console.log(key)
    let projectid = this.$store.state.project.projectid;
    //sessionStorage.removeItem(key);//清空session

    getfootinfo({ apartmentSid: projectid }).then((res) => {
      this.APinfo=res.data.result[0];
      // window.sessionStorage.setItem(key, JSON.stringify(res))
      saveSession(key, JSON.stringify(res))
    });


    // const session = JSON.parse(window.sessionStorage.getItem(key))
    // if(session===''||session===null){
    //   //判断缓存,不存在
    //   console.log("请求接口"+key)
    //
    //   getfootinfo({ apartmentSid: projectid }).then((res) => {
    //     this.APinfo=res.data.result[0];
    //     window.sessionStorage.setItem(key, JSON.stringify(this.APinfo))
    //   });
    // }else {
    //   console.log("读缓存")
    //   this.APinfo = JSON.parse(window.sessionStorage.getItem(key))
    // }

    key='Dcitionary';
    console.log(key)
    //sessionStorage.removeItem(key);//清空session
    const sessionDcitionary = JSON.parse(window.sessionStorage.getItem(key))
    if(sessionDcitionary===''||sessionDcitionary===null){
      //判断缓存,不存在
      console.log("请求接口"+key)

      getDcitionaryList({ type: "DISPLAY"}).then((res) => {
        this.forCofing(res)
        window.sessionStorage.setItem(key, JSON.stringify(res))
      });//字典

    }else {
      console.log("读缓存")
      this.forCofing(JSON.parse(window.sessionStorage.getItem(key)))
    }


    if (this.$store.state.project.servedata) {
      // getfootinfo({ apartment_Sid: projectid }).then((res) => {
      //
      //   this.servicePhoto1 = staticurl + res.data.result[0].alipay_Ld_Qrcode;
      //   this.servicePhoto2 = staticurl + res.data.result[0].wechat_Ld_Qrcode;
      // });

      //             src="https://tbp.cloud.tencent.com/tbp-h5-v2/?AgentId=NIHRYhvo%2Fr93abahKLsOIZGDyr2NmPMrUzdEjZa5hCfwWThFfZhTOL%2Bxj39yE2DTIQa%2BgY5wGNd%2FeAxmy%2F%2FVcn7BuV9ahnHhGQVjzKiwmNE%3D&ufc=&ubc=&bfc=&bbc=&tfc=&tbc="
      // getDcitionaryList({ type: "DISPLAY"}).then((res) => {
      //
      //   for (var i = 0; i < res.obj.length; i++) {
      //     switch (res.obj[i].dictionary_KEY) {
      //       case 'WXVERSION':
      //
      //         break;
      //       case 'UPDATEDICLIMIT':
      //
      //         break;
      //
      //       case 'PRICE_IOS_GZH':
      //         break;
      //       case 'PURCHASE_IOS_GZH':
      //         break;
      //       case 'DISPLAYSUBSCRIBETEXT':
      //         break;
      //       case 'CHAT_COMPUTE':
      //         break;
      //       case 'INDEXZBDIV':
      //         break
      //       case 'FAKEINDEXPAGE':
      //         break
      //       case 'MAIN':
      //         break;
      //       case 'HEALTH_AIDE':
      //         //云客服 是否启用
      //         this.enableChat = res.obj[i].dictionary_VALUE;
      //         break;
      //       case 'HEALTH_AIDE_ICON':
      //         //云客服ICO
      //         break;
      //       case 'HEALTH_AIDE_URL':
      //         //云客服url
      //         this.chatUrl = res.obj[i].dictionary_VALUE;
      //         break;
      //
      //     }
      //   }
      //
      // });//字典

    }
  },

};
</script>

<style scoped>
.sidebarwrap {
  width: 100px;
  background-image: url("../assets/images/sidebar.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  right: 30px;
  top: 40%;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.sidebarsqwrap {
  width: 60px;
  position: fixed;
  right: 0px;
  top: 40%;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.sideitem {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 2px;
  margin: 0 auto;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.toplogo {
  font-size: 22px;
}

.sidebar_card {
  width: 200px;
  /* height: 300px; */
  position: absolute;
  top: -10px;
  left: -250px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: none;
  color: #000000;
  padding: 20px 0;
}
.sidebarCardImg{
  width: 100%;
  height: 100%;
}
.sidebar_chat {
  width: 500px;
  height: 500px;
  position: fixed;
  /*top: -200px;*/
  bottom:10px;
  /*left: -550px;*/
  right:150px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: #000000;
  padding: 0px 0px;
}
.sidebar_chat_btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding:  0px;
  color: #000000;
  border-color: #fff;
  border-width: 1px;
  border-color: lightgray;
  background-color: #fff;
}

.sideitem:hover .sidebar_card {
  display: block;
}

.sidebar_card:hover {
  display: block;
}
.sidebar_card_chat:hover {
  display: block;
}

.sidebar_card_text {
  line-height: 22px;
}
@media (max-width: 600px){
  .sidebarwrap{
    right: 0px;
    width: 50px;
  }
  .sidebarsqwrap{
    width: 40px;
  }
}
</style>
