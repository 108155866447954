

// 是否是图片
export function isAssetTypeAnImage(ext) {
    // 防止传入的是完整url 路径
    ext=  ext.toLowerCase()
    let index = ext.lastIndexOf(".");
    let result = ext.substr(index + 1);
    return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(result) != -1;
}
// 是否是视频
export function  isAssetTypeAnVedio(ext) {
    ext=  ext.toLowerCase()
    let index = ext.lastIndexOf(".");
    let result = ext.substr(index + 1);
    return ['mp4'].indexOf(result) !== -1;
}
// 替换换行
export function strRelacebr(str){
    return str.replace(/(\n|\r|\r\n|\\n|\\r|\\r\\n|↵)/g, '<br/>')
}