
import { gettoken , getuserinfo , getuserpwd } from '@/utils/user'
import { settoken , setuserinfo , setuserpwd } from '@/utils/user'
import { cleantoken , cleanuserinfo , cleanuserpwd } from '@/utils/user'

const state = {
  token: gettoken(),
  userinfo: getuserinfo(),
  userpwd: getuserpwd(),
}

const mutations = {
  set_token: (state, data) => {
    state.token = data.data;
    settoken(data.data);
  },
  set_userinfo: (state, data) => {
    state.userinfo = data.data;
    setuserinfo(data.data);
  },
  set_userpwd: (state ,data) => {
    state.userpwd = data.data;
    setuserpwd(data.data);
  },
  set_cleanuserpwd: (state ,data) => {
    state.userpwd = false;
    cleanuserpwd();
  },
  set_loginout: (state, data) => {
    cleantoken();
    cleanuserinfo();
    state.token = '';
    state.userinfo = '';
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        console.log(response)
        // response.data&&response.data.roles&&response.data.roles[0]&&(response.data.roles[0] ='editor'); 
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
