<template>
  <!--运营模式 --服务内容  -->
    <div class="divWkuangFlex divMa">

      <titleDiv :title="yyms.content.patternName" english-title="YUNYINGMOSI"></titleDiv>

      <div class="yqjs_item">
        <img v-if="!!yyms.content.patternLogo"
            class="yqjsimg wow animate__animated animate__fadeInLeft"
            :src="staticurlSrc+yyms.content.patternLogo"
        />
        <div :class="yyms.content.patternLogo?'yqjstext':'yqjstextall'+' wow animate__animated animate__fadeInRight'">
          <div v-html="yyms.content.patternContent"></div>
        </div>
      </div>
    </div>

</template>

<script>
import '@/style/css/index.css';
import titleDiv from '@/components/title.vue'
import {yymsapi} from "@/api/zjyqapi.js";
import {staticurl} from "@/utils/baseurl";
import { saveSession } from "@/utils/windowStorage";

export default {
  components:{
    titleDiv
  },
  name: "yyms",
  data(){
    return{
      project:this.$store.state.project,
      projeSid:'',
      staticurlSrc:staticurl,
      yyms: {
        content: {
          patternLogo:'',
          patternName: "运营模式",
          patternEnglishName: "YUNYINGMOSI",
          patternContent: '',
        },
      },
    }
  },
  props:["titleEnglish","bjImgHidden"],
  beforeMount(){
    let key='yyms';
    console.log(key)
    this.dateStaticurl = staticurl;
    //this.projeSid = this.$store.state.project.projectid;//项目id
    //sessionStorage.removeItem(key);//清空session

    // 运营模式
    yymsapi({ apartmentSid : this.project.projectid }).then((res) => {
      this.yyms.content = res.data.result[0];
      // window.sessionStorage.setItem(key, JSON.stringify(this.yyms.content))
      saveSession(key,JSON.stringify(res))
    })

  }
}
</script>

<style scoped>

.yqjswrap {
  width: 100%;
  margin: 70px 0;
}

.yqjs_item {
  display: flex;
  align-items: center;
  margin-top: 30px;
  width:100%;
}

.yqjsimg {
  width: 50%;
}

.yqjstext {
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 40px;
  font-size: 14px;
  /*这个宽度 要动态给 左边没有才给 100%  ；左边你有 则各50%*/
  /*width:100%;*/

}

.yqjstextall {
  line-height: 30px;
  box-sizing: border-box;
  padding: 0px 40px;
  font-size: 14px;
  /*这个宽度 要动态给 左边没有才给 100%  ；左边你有 则各50%*/
  width:100%;

}
.yqjstext >>>img{
  width: 100%;
}
.yqjstextall >>>img{
  width: 100%;
}

@media (max-width: 600px){
  .yqjswrap {
    margin: 30px 0;
  }
  .yqjs_item{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .yqjsimg{
    width: 100%;
  }
  .yqjstext {
    padding:0px;
  }
}
</style>
