import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import project from '@/store/modules/project'

Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

export default new Vuex.Store({
  modules:{
    user ,
    project ,
  },
})
