<template>
    <div class="indexbox">
        <div class="footcol">
             <div class="foot_text logo"><img :src="logo" /></div>
            <div class="foot_text gsname">
                {{ name }}
            </div>
        </div>

        <!-- 滑块 -->
        <div class="swiper-container">
          <div class="swiperbgimg"></div>
          <div class="swiper-wrapper">
              <!-- <div class="swiper-slide">
                <img class="swiperimg" :src="@/assets/images/swiper1.jpg">
              </div>
              <div class="swiper-slide">
                <img class="swiperimg" :src="@/assets/images/swiper2.jpg">
              </div>
              <div class="swiper-slide">
                <img class="swiperimg" :src="@/assets/images/swiper3.jpg">
              </div> -->
              <div class="swiper-slide" v-for="(item,index) in swiper.imgs" :key="index">
                <img :style="[{height:bannerIMGheight}]" class="swiperimg" :src="item.imgurl" v-if="isIMG(item.imgurl)">
                  <video class="swiperimg" :src="item.imgurl" v-if="!isIMG(item.imgurl)" name=""></video>
              </div>
          </div>
        </div>
        <!-- 申请流程  合作流程-->
        <div class="sqlcwrap wow animate__animated animate__flipInX" v-if="1==0">
          <div class="sqlccontent">
            <div class="sqlc_left">
              <div class="sqlc_left_title">{{ryzn.title}}</div>
              <div class="sqlc_left_title">APPLICATION PROCESS</div>
            </div>
            <div class="sqlc_right">

                  <div class="sqlc_item" v-for="(item, index) in ryzn.list" :key="index">
                    <div class="sqlc_item_title">{{item.guide_Name}} </div>
                    <div class="sqlc_item_text">{{item.guide_Content}}</div>
                  </div>

<!--              <div class="sqlc_item">-->
<!--                <div class="sqlc_item_title">ONE </div>-->
<!--                <div class="sqlc_item_text">签订协议 / 前期策略咨询,提供服务方案及报价签约合同</div>-->
<!--              </div>-->
<!--              <div class="sqlc_item">-->
<!--                <div class="sqlc_item_title">TWO</div>-->
<!--                <div class="sqlc_item_text">市场分析/ 行业分析 竟品分析 品牌分析</div>-->
<!--              </div>-->
<!--              <div class="sqlc_item">-->
<!--                <div class="sqlc_item_title">THREE </div>-->
<!--                <div class="sqlc_item_text">方案制定/ 推广策划 一企一案</div>-->
<!--              </div>-->
<!--              <div class="sqlc_item">-->
<!--                <div class="sqlc_item_title">FOUR </div>-->
<!--                <div class="sqlc_item_text">运营迭代/ 项目执行 月报/阶段性报告 定期复盘会议 迭代策划方案</div>-->
<!--              </div>-->
<!--              <div class="sqlc_item">-->
<!--                <div class="sqlc_item_title">FIVE</div>-->
<!--                <div class="sqlc_item_text">项目交付/ 项目总结报告</div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <!--关于我们-->
        <div class="uswrap" v-if="1==0">
          <div class="uscontent">
            <div class="ustitle">关于我们</div>
            <div class="ustitle2">ABOUT US</div>
            <div class="us_item">
              <img class="usimg wow animate__animated animate__fadeInLeft" :src="yqjs.img" />
              <div class="ustext wow animate__animated animate__fadeInRight">
                <!-- <div class="ustext1">
                  盼云跨境，是一家以抖音小店店主、抖音主播为服务对象的综合电子商务产业园，为抖音百万商家及主播提供包括工商注册、财税服务、知识产权、供应链等一站式配套服务，免费提供注册地址，入园
                  享受多项福利政策。盼云跨境依托专业的核心服务团队，借助庞大的商家主播群体，全方位打造一个集电商直播、供应链于一体的新零售产业服务平台，以点带面，实现直播电商产业服务升级。
                </div>
                <div class="ustext2">
                  盼云跨境旗下“抖音小号”，作为其移动端产品，集品宣、一键入园服务选购、客服咨询四为一体，为广大入园商家提供更便捷、更全面的运营指南。
                </div> -->
                <div v-html="yqjs.text"></div>
              </div>
            </div>
          </div>
        </div>
        <!--福利政策-->
        <div class="flzcwrap" v-if="1==0">
          <div class="flzccontent wow animate__animated animate__fadeInLeft">
            <div class="flzcbox">
              <!-- <div class="flzc_title">财税奖励计划</div>
              <div class="flzc_text">
                园区支持电商经营主体入驻可享受财政奖励政策;针对年实际税费贡献在20万元以上的纳税人，给予地方留存部分的60%到80%的奖励。
              </div>
              <div class="flzc_title">人才引培计划</div>
              <div class="flzc_text">
                针对电商人才培训机构,符合人才引培要求的给予机构每年不超过30万的补助,针对贡献大的电商企业，择优选择符合条件的核心人才,享受相关住房政策。
              </div> -->
              <div v-for="(item,index) in yqhl.list" :key='index + new Date()'>
                <div class="flzc_title" v-show="index < 2">{{item.bonus_Name}}</div>
                <div class="flzc_text" v-show="index < 2">
                  {{item.bonus_Content}}
                </div>
              </div>
              <div class="flzc_title1">福利政策</div>
              <div class="flzc_title2">WELFARE POLICY</div>
              <!-- <div class="flzc_title">楼宇扶持计划</div>
              <div class="flzc_text">
                园区对优质的电子商务企业给予为期三年，每年不超过90万元的定额租金补助政策,扶持园区电商企业成长。
              </div>
              <div class="flzc_title">亿元补贴计划</div>
              <div class="flzc_text">
                园区启动亿元专项补贴计划。针对首批入园商家,入驻园区的每个市场主体可领取1000元补贴,用于抵扣园区内税务、审计、商标注册等服务。
              </div> -->
              <div v-for="(item,index) in yqhl.list" :key='index'>
                <div class="flzc_title" v-if="index >= 2">{{item.bonus_Name}}</div>
                <div class="flzc_text" v-if="index >= 2">
                  {{item.bonus_Content}}
                </div>
              </div>
            </div>
          </div>
          <img class="flzcimg wow animate__animated animate__fadeInRight" :src="yqhl.img"/>
        </div>
        <!--配套服务-->
        <div class="ptfwwrap" v-if="1==0">
          <div class="ptfwcontent">
            <div class="ptfwtitle">配套服务</div>
            <div class="ptfwtitle2">SUPPORTING SERVICES</div>
          </div>
          <div class="fwxrow wow animate__animated animate__fadeInUp">
            <!-- <div class="fwx_item">
              <img src="../assets/images/zxyhkh.png"/>
              <div class="fwxtext">在线银行开户</div>
            </div>
            <div class="fwx_item">
              <img src="../assets/images/fpxgyw.png"/>
              <div class="fwxtext">发票相关业务</div>
            </div>
            <div class="fwx_item">
              <img src="../assets/images/dzqzfw.png"/>
              <div class="fwxtext">电子签章服务</div>
            </div>
            <div class="fwx_item">
              <img src="../assets/images/zscqfw.png"/>
              <div class="fwxtext">知识产权服务</div>
            </div> -->
            <div class="fwx_item" v-for='(item,index) in service.list' :key="index">
              <img class="serviceimg_item" :src="item.service_Logo"/>
              <div class="fwxtext">{{item.type_Name}}</div>
            </div>
          </div>
        </div>
        <!--弹窗-->
        <el-dialog
          class="rytz_model"
          top='30vh'
          title=""
          :visible="modelconfig.show"
          :show-close='false'
          width="750px"
        >
          <span>这是一段信息</span>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="iknow" round>我 知 道 了</el-button>
          </span>
        </el-dialog>
        <!-- 申请执照 -->
        <!-- <div class="sqzzwrap"> -->
          <!-- <img class="sqzzbtn" src="../assets/images/sqzz.png" @click="to_zzsq" /> -->
          <!-- <img class="sqzzbtn2" :src="sqzz2_img" > -->
        <!-- </div> -->
        <!-- 侧边导航 -->
        <sidebar ref="mysiderbar"></sidebar>
    </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
import { yqjsapi , getprojectspi , ryznapi , yqhlapi , indexserviceapi , swiperapi } from '@/api/index.js';
import sidebar from '@/components/sidebar'
import { sqzzurl , staticurl,statiVcurl } from '@/utils/baseurl'
import wow from 'wowjs'
import  {isAssetTypeAnImage,} from '@/utils/common.js'

let pcHeight = (document.documentElement.clientHeight-16)+'px';
let pWidth = document.documentElement.clientWidth;
if(pWidth<600){
  pcHeight=(document.documentElement.clientHeight-48)+'px';
}
pcHeight=(document.documentElement.clientHeight)+'px';
export default {
    components:{
      sidebar
    },
    data() {
        return {
            logo: require('@/assets/images/logo.png'),
            name:'盼云跨境',
            mySwiper: {},
          bannerIMGheight:pcHeight,
            swiper: {
              imgs:[],
            },
            yqjs: {
              text: '',
              img: '',
            },
            ryzn: {
                title:'合作流程',
              list: [{
                guide_Name: "园区账号注册",
                guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
              },{
                guide_Name: "园区账号注册",
                guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
              },{
                guide_Name: "园区账号注册",
                guide_Content: "点击网页右上角注册按钮,填写真实信息完成登记。",
              }],
            },
            yqhl:{
              list:[],
              img: '',
            },
            service:{
              list: []
            },
            modelconfig:{
              show: false,
            },
            sqzz2_img: "./assets/images/sqzz2_white.png",
        }
    },
    methods:{
        isIMG(ext)
        {
            var isOK= isAssetTypeAnImage(ext);
            console.log(isOK)
            return isOK
        }
        ,
        toENWords(s)
        {//把数字  转英文单词
            var dg = ['zero','one','two','three','four','five','six','seven','eight','nine'];
            var tn =['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen','eighteen','nineteen'];
            var tw = ['twenty','thirty','forty','fifty','sixty','seventy','eighty','ninety'];
            var th = ['','thousand','million', 'milliard','billion'];
            s = s.toString();

            s =s.replace(/[\, ]/g,'');
            if (s != parseFloat(s)) return 'not a number'; var x =s.indexOf('.');
            if (x == -1) x = s.length; if (x > 15)
                return 'too big';
            var n =s.split('');
            var str = '';
            var sk = 0;
            for (var i=0; i < x; i++) {

                if ((x - i) % 3 == 2) {
                    if (n[i] == '1') {
                        str += tn[Number(n[i + 1])] + ' ';
                        i++;
                        sk = 1;
                    } else if (n[i] != 0) {
                        str += tw[n[i] - 2] + ' ';
                        sk = 1;
                    }
                } else if (n[i] != 0) {
                    str += dg[n[i]] + ' ';
                    if ((x - i) % 3 == 0) str += 'hundred ';
                    sk = 1;
                }
                if ((x - i) % 3 == 1) {
                    if (sk) str += th[(x - i - 1) / 3] + ' ';
                    sk = 0;
                }
            }// for
            if (x != s.length) {
                var y = s.length;
                str += 'point ';
                for (var i = x + 1; str=str.replace(/\s+/g, ' ');) {

                }
            }
            return  str
        }
        ,
      serve(){
        this.$router.push({
          path: '/serve'
        })
      },
        hideChat(){
            // 相等代表是 父级节点触发
          // if(e.currentTarget == e.target)
          // {
               this.$refs.mysiderbar.hideChat()
          // }


        },
      iknow(){
        this.modelconfig.show = false;
      },
      that_scroll(wrapdom,that_dom,callback1,callback2) {
        wrapdom.onscroll = function(){
          var curr_element = that_dom;
          var scroll_t = document.documentElement.scrollTop;
          var viewheight = document.documentElement.clientHeight;
          var self_top = curr_element.offsetTop;
          //超出被观测对象顶部边缘
          if( (scroll_t + viewheight) > self_top ){
            // console.log('超出')
            callback1()
          }else{
            //  console.log('未超出')
            callback2();
          }
        }
      },
      to_zzsq(){
        if(this.$store.state.user.token){
          location.href = sqzzurl();
        }
        else{
          this.$router.push({
            path: '/login'
          })
        }
      }
    },
    beforeMount(){
      let projectid = this.$store.state.project.projectid;



      if(this.$store.state.project.servedata){


        yqjsapi({apartment_Sid : projectid}).then((res) => {
          this.yqjs.text = res.data.result[0].about_Content;
          this.yqjs.img = staticurl + res.data.result[0].about_Logo;
        })
        // 基本资料
        getprojectspi({apartment_Sid : projectid}).then((res) => {
            this.name = res.data.result[0].apartment_Name;
            this.logo = staticurl + res.data.result[0].pb_Top_Ico;

        })
          // 入园指南 合作流程
        ryznapi({apartment_Sid : projectid}).then((res) => {
          this.ryzn.list = res.data.result;
        })
        yqhlapi({apartment_Sid : projectid}).then((res) => {
          this.yqhl.list = res.data.result
          this.yqhl.img = staticurl + res.data.result[0].bonus_Logo;
        })
        indexserviceapi({apartment_Sid : projectid}).then((res) => {
          this.service.list = res.data.result.map((item,index) => {
            item.service_Logo = staticurl + item.type_Image;
            return item;
          })
        })
        swiperapi({apartment_Sid : projectid, position_Sid: '1'}).then((res) => {
          this.swiper.imgs = res.data.result.map((item) => {
            item.imgurl = staticurl + item.picture;

              //视频判断
            // console.log('------------------测试--------------------------')
            // item.imgurl =  'https://uwooz.oss-cn-hangzhou.aliyuncs.com/image/cmd_rm.gif'
            // item.imgurl = require('../assets/images/homeBg1.gif')
            console.log( item.imgurl)
            return item;
          })
        })
      }
      else{
         this.swiper.imgs = [
        // {imgurl: './assets/images/homeBg1.gif'},
          {imgurl: './assets/images/swiper1.jpg'},
          {imgurl: './assets/images/swiper2.jpg'},
          {imgurl: './assets/images/swiper3.jpg'}
        ];
        this.yqjs.text = `<div class="ustext1">
                       盼云跨境，是一家以抖音小店店主、抖音主播为服务对象的综合电子商务产业园，为抖音百万商家及主播提供包括工商注册、财税服务、知识产权、供应链等一站式配套服务，免费提供注册地址，入园
                       享受多项福利政策。盼云跨境依托专业的核心服务团队，借助庞大的商家主播群体，全方位打造一个集电商直播、供应链于一体的新零售产业服务平台，以点带面，实现直播电商产业服务升级。
                     </div>
                     <div class="ustext2">
                       盼云跨境旗下“抖音小号”，作为其移动端产品，集品宣、一键入园服务选购、客服咨询四为一体，为广大入园商家提供更便捷、更全面的运营指南。
                     </div>`;
        this.yqjs.img = './assets/images/us.png';
        this.yqhl.list = [
          {
            bonus_Name: "财税奖励计划",
            bonus_Content: "园区支持电商经营主体入驻可享受财政奖励政策;针对年实际税费贡献在20万元以上的纳税人，给予地方留存部分的60%到80%的奖励。",
          },{
            bonus_Name: "人才引培计划",
            bonus_Content: "针对电商人才培训机构,符合人才引培要求的给予机构每年不超过30万的补助,针对贡献大的电商企业，择优选择符合条件的核心人才,享受相关住房政策。",
          },{
            bonus_Name: "楼宇扶持计划",
            bonus_Content: "园区对优质的电子商务企业给予为期三年，每年不超过90万元的定额租金补助政策,扶持园区电商企业成长。",
          },{
            bonus_Name: "亿元补贴计划",
            bonus_Content: " 园区启动亿元专项补贴计划。针对首批入园商家,入驻园区的每个市场主体可领取1000元补贴,用于抵扣园区内税务、审计、商标注册等服务。",
          }
        ];
        this.yqhl.img = './assets/images/flzc.png';
        this.service.list = [
          {
            service_Logo:'./assets/images/zxyhkh.png',
            type_Name:'在线银行开户',
          },
          {
            service_Logo:'./assets/images/fpxgyw.png',
            type_Name:'发票相关业务',
          },
          {
            service_Logo:'./assets/images/dzqzfw.png',
            type_Name:'电子签章服务',
          },
          {
            service_Logo:'./assets/images/zscqfw.png',
            type_Name:'知识产权服务',
          }
        ];
      }
    },
    mounted(){
        let that = this;
        this.mySwiper = new Swiper('.swiper-container', {
            // initialSlide: 0,
            // direction: 'vertical',
            // effect : 'coverflow',
            effect : 'fade',
            mousewheelControl : true,
            autoplay:{
              delay: 2000,
            },
            on:{
	        	init:function(swiper){
                //     // console.log(swiper)
                //     let slide = swiper.slides[0].classList;
                //     // console.log(slide)
	        	  	// slide.add('ani-slide');
	        	},
	        	transitionStart: function(swiper){
	        	  	// for(let i = 0; i < swiper.slides.length; i++){
	        	  	//   	let slide = swiper.slides[i].classList;
	        	  	//     slide.remove('ani-slide');
	        	  	// }
	        	},
	        	transitionEnd: function(swiper){
                //   // console.log(swiper)
	        	  	// let slide = swiper.slides[swiper.activeIndex].classList;
	        	  	// slide.add('ani-slide');
	        	},
	        }
        })
        this.that_scroll(document.getElementsByTagName('body')[0],document.getElementsByClassName('sqlcwrap')[0],function(){
          that.sqzz2_img = "./assets/images/sqzz2_purple.png"
        },function(){
          that.sqzz2_img = "./assets/images/sqzz2_white.png"
        });
        this.$nextTick(() => {
          new wow.WOW().init()
        })

        // 页面增加全局点击
        window.addEventListener('click', this.hideChat)
    },
    beforeDestroy() {
        /* 组件销毁的时候要移除侦听器 */
        window.removeEventListener('click', this.hideChat)
    },
}

</script>

<style scoped>


    .footcol {
        color: #ffffff;
        position: absolute;
        top:20px;
        left:50px;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        text-align: center;
    }


    .logo {
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
    }

    .logo img {
        width: 60px;
    }

    .gsname {
        /*line-height: 50px;*/
        color: white;
        /*margin-bottom: 20px;*/
    }


  .indexbox{
    width: 100%;
    overflow-x: hidden;
  }

  .swiper-container {
    width: 100%;
    /* height: 90%; */
    height: auto;
    position: relative;
    overflow: revert;
  }

  .swiperbgimg{
    width: 100%;
    height: 100%;
    /* background-image: url(""); */
    background-size: 100% 100%;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiperimg{
    width: 100%;
    height: 100%;
  }

  .sqlcwrap{
    width: 100%;
    background-image: url("../assets/images/p.png");
    background-repeat: no-repeat;
    background-size:auto 90%;
    background-position: 20px bottom;
  }

  .sqlccontent{
    width: 1050px;
    margin: 0 auto;
    display: flex;
  }

  .sqlc_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sqlc_left_title:nth-child(1){
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
    line-height: 60px;
  }

  .sqlc_left_title:nth-child(2){
    font-size: 15px;
    color: rgba(93, 46, 140, 1);
  }

  .sqlc_right{
    margin-left: 110px;
  }

  .sqlc_item{
    display: flex;
    margin: 30px 0;
    transition: all 1s;
  }

  .sqlc_item:hover{
    transform: scale(1.2,1.2);
  }

  .sqlc_item_title{
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
  }

  .sqlc_item_text{
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-left: 20px;
  }

  .sqlc_item:nth-child(2){
    margin-left: 35px;
  }

  .sqlc_item:nth-child(3){
    margin-left: 70px;
  }

  .sqlc_item:nth-child(4){
    margin-left: 115px;
  }
  .sqlc_item:nth-child(5){
    margin-left: 145px;
  }

  .uswrap{
    width: 100%;
    background-image: url("../assets/images/A.png");
    background-repeat: no-repeat;
    background-size:auto 60%;
    background-position: 99% center;
  }

  .uscontent{
    width: 1050px;
    margin: 0 auto;
    margin-top: 35px;
  }

  .ustitle{
    text-align: center;
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
    line-height: 60px;
  }

  .ustitle2{
    font-size: 15px;
    color: rgba(93, 46, 140, 1);
    text-align: center;
  }

  .us_item{
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .usimg{
    width: 50%;
  }

  .ustext{
    line-height: 30px;
    box-sizing: border-box;
    padding: 30px 40px;
    font-size: 14px;
  }

  .ustext1,.ustext2{
    letter-spacing: 1px;
    text-indent: 30px;
  }

  .flzcwrap{
    width: 100%;
    position: relative;
    background-image: url("../assets/images/w.png");
    background-repeat: no-repeat;
    background-size:auto 60%;
    background-position: 20px center;
  }

  .flzccontent{
    width: 1050px;
    margin: 0 auto;
    margin-top: 220px;
  }

  .flzcbox{
    width: 50%;
  }

  .flzc_title{
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
  }

  .flzc_text{
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 25px;
    box-sizing: border-box;
    padding-right: 20px;
  }

  .flzc_title1{
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
    margin-top: 15px;
  }

  .flzc_title2{
    font-size: 15px;
    color: rgba(93, 46, 140, 1);
    margin-bottom: 15px;
  }

  .flzcimg{
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .ptfwwrap{
    width: 100%;
    background-image: url("../assets/images/s.png");
    background-repeat: no-repeat;
    background-size:auto 60%;
    background-position: 99% center;
  }

  .ptfwcontent{
    width: 1050px;
    margin: 0 auto;
    margin-top: 200px;
  }

  .ptfwtitle{
    text-align: center;
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
    line-height: 60px;
  }

  .ptfwtitle2{
    font-size: 15px;
    color: rgba(93, 46, 140, 1);
    text-align: center;
  }

  .fwxrow{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 78px;
  }

  .fwx_item{
    margin: 0 100px;
  }

  .fwxtext{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 140px;
    color: #333333;
  }

  .sqzzwrap{
    height: 100px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/images/modelbg.png");
    background-size: 60% auto;
    background-position:center top;
    background-repeat: no-repeat;
  }

  .sqzzbtn{
    width: 400px;
  }

  .sqzzbtn2{
    width: 40px;
    margin-left: 20px;
  }

  .serviceimg_item{
    width: 160px;
    transition: all 0.6s;
  }

  .serviceimg_item:hover{
    box-shadow: 1px 1px 34px rgba(0,0,0,0.4);
    transform: scale(1.2,1.2);
  }

  /* element样式从写 */
  >>>.rytz_model .el-dialog__footer{
    text-align: center !important;
  }

  >>>.rytz_model .el-dialog__footer .el-button--primary{
    background: linear-gradient(to bottom right, #721172 , #ba79ee);
    border: none;
  }

  >>>.rytz_model .el-dialog__body{
    max-height: 400px;
  }

  >>>.rytz_model .el-dialog{
    border-radius: 12px;
  }
</style>
