import request from '@/utils/request'

export function yqjsapi(data) {
  return request({
    url: '/v2/wsyg/about',
    method: 'POST',
    data: data
  })
}

export function yqlnapi(data) {
  return request({
    url: '/v2/wsyg/notion',
    method: 'POST',
    data: data
  })
}

export function yymsapi(data) {
  return request({
    url: '/v2/wsyg/pattern',
    method: 'POST',
    data: data
  })
}

export function swiperapi(data) {
  return request({
    url: '/v2/wsyg/adPosition',
    method: 'POST',
    data: data
  })
}
  export function singleapi(data) {
    return request({
      url: '/v2/wsyg/single',
      method: 'POST',
      data: data
    })

}
