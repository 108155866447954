import request from '@/utils/request'


export function getfootinfo(data) {
  // 加入缓存
  var result=sessionStorage.projectInfo
  // if(result!=null&&result!='') return result

  result= request({
    url: '/v2/wsyg/getApartment',
    method: 'POST',
    data: data
  })

  sessionStorage.projectInfo=result
  return result
}

/***
 * 获取字典列表
 * @param data
 */
export function getDcitionaryList(data) {
  return request({
    url: '/toconfig/getconfigtypeList',
    method: 'POST',
    data: data,
  })
}
