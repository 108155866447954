<template>
  <div id="app" ref="app" class="appCss">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view class="routerview"/>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods:{
    screen(){
      //按照 1315 X 937 设计稿
      if(screen.availWidth < 1315){
        let widthbl = screen.availWidth / 1315;
        let heightbl = screen.availHeight / 1024;
        this.$refs.app.style.transform = "scale(" + widthbl + "," + widthbl + ")";
      }
      else{
        this.$refs.app.style.transform = "scale(1,1)";
      }
    }
  },
  mounted(){
    // this.screen();
    // window.onresize = () => {
    //   this.screen();
    // }
  },
}
</script>

<style>

::-webkit-scrollbar{
  display: none;
}

html,body{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

#app,.nav,.routerview {
  width: 100%;
  height: 100%;
  position: relative;
}

#app{
  /* min-width: 920px; */
  /* 根据UI设计稿宽度1315px设计 */
  /*min-width: 1315px;*/
  transform-origin:0% 0%;
}
</style>
