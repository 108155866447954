<template>
  <!--关于我们-->

    <div class="divWkuangFlex divMa bjt1 bjtcssYbping">

      <titleDiv :title="yqjs.aboutName" english-title="ABOUT US"></titleDiv>

      <div class="us_item">
        <img v-if="!!yqjs.aboutLogo"
            class="usimg wow animate__animated animate__fadeInLeft"
            :src="staticurlSrc+yqjs.aboutLogo" />
        <div
            :class="!!yqjs.aboutLogo?'ustext wow animate__animated animate__fadeInRight':'ustextS wow animate__animated animate__fadeInRight'">
          <div v-html="yqjs.aboutContent"></div>
        </div>
      </div>
    </div>

</template>

<script>
import '@/style/css/index.css';
import {yqjsapi} from "@/api";
import {staticurl} from "@/utils/baseurl";
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "gywm",
  data() {
    return {
      project:this.$store.state.project,
      staticurlSrc:staticurl,
      yqjs:[]
    }
  },
  props:["titleEnglish","bjImgHidden"],
  beforeMount(){
    let key='gywm';
    console.log(key)
    //let projeSid = this.$store.state.project.projectid;//项目id
    //sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)

        yqjsapi({apartmentSid : this.project.projectid}).then((res) => {
          this.yqjs=res.data.result[0];
          window.sessionStorage.setItem(key, JSON.stringify(this.yqjs))
        })

    }else {
      console.log("读缓存")
      this.yqjs = JSON.parse(window.sessionStorage.getItem(key))
    }
  },
}
</script>

<style scoped>
.us_item{
  display: flex;
  align-items: center;
}
.usimg{
  width: 100%;
}
.ustext{
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 14px;
}
.ustextS{
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  padding-left: 0px;
  font-size: 14px;
}
.ustext1,.ustext2{
  letter-spacing: 1px;
  text-indent: 30px;
}
@media (max-width: 600px){
  .us_item{
    flex-direction: column;
  }
}
</style>
