import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
 import store from '@/store'
// import { getToken } from '@/utils/auth'
import { apiurl } from '@/utils/baseurl'
import { saveSession,getSession } from "@/utils/windowStorage";
const CancelToken = axios.CancelToken;
// create an axios instance
const service = axios.create({
  baseURL: apiurl, //process.env.VUE_APP_BASE_API // url = base url + request url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {


      console.log("11service 请求的接口是="+config.url)
      if(store.state.project.enableSession)
      {    let key="";
          switch (config.url) {
              case '/v2/wsyg/getApartment':
                     key='getInfo';
              break;
              case '/v2/wsyg/pattern':
                  key='yyms';
                  break;
          }
          const singlePageQ =getSession(key)
          if((!!singlePageQ)) {
              {
                  console.log("11service 读缓存" + key)
                  // var temp = JSON.parse(window.sessionStorage.getItem(key))
                  let cancel
                  // 设置cancelToken对象
                  config.cancelToken = new axios.CancelToken(function(c) {
                      cancel = c
                  })

                  cancel(singlePageQ)   // 通知取消  请求不再继续 同时会抛出错误

              }
          }

      }


      // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    // }
    config.headers['token'] = '123456';
    // config.headers['uid'] = '123456';
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)


// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
    return res;
  },
  error => {
    console.log('err' + error) // for debug

    // 针对自己取消的事件 特殊处理
     if( axios.isCancel(error))
     {// 取消的事件
       // var  res={data:{result:[]}}
       //   res.data.result[0]= JSON.parse(error.message)
         var res=JSON.parse(error.message)
         return Promise.resolve(res) // 按成功处理 继续处理
     }
     else
     {
         Message({
             message: error.message,
             type: 'error',
             duration: 5 * 1000
         })
         return Promise.reject(error)
     }

  }
)

export default service
