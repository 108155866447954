<template>
<!--ICP组件-->
    <div class="footbottom footbottomS">
      <div class="bahgs">COPYRIGHT ©2023 {{APinfo.apartmentName}}</div>
      <div class="bah">
      <span style="color:white;text-decoration: underline"
            onclick="window.open('https://beian.miit.gov.cn')">ICP备案号：{{APinfo.webRecordNo}}</span>
        <span v-if="!!APinfo.webPcnRecordNo">公安局备案号：{{APinfo.webPcnRecordNo}} </span>
    </div>
    </div>
</template>

<script>
import { getfootinfo } from "@/api/foot";
import { saveSession } from "@/utils/windowStorage";

export default {
  data() {
    return {
      APinfo:[],
    };
  },
  beforeMount() {
    let key='getInfo';
    console.log(key)
    let projectid = this.$store.state.project.projectid;

      getfootinfo({ apartmentSid: projectid }).then((res) => {
          this.APinfo=res.data.result[0];
          // window.sessionStorage.setItem(key, JSON.stringify(res))
          saveSession(key, JSON.stringify(res))
      });

    // sessionStorage.removeItem(key);//清空session
    //   const singlePageQ = window.sessionStorage.getItem(key)
    //
    //   if(!this.$store.state.project.enableSession &&!singlePageQ){
    //   //判断缓存,不存在
    //   console.log("请求接口"+key)
    //
    //   getfootinfo({ apartmentSid: projectid }).then((res) => {
    //     this.APinfo=res.data.result[0];
    //     window.sessionStorage.setItem(key, JSON.stringify(this.APinfo))
    //   });
    // }else {
    //   console.log("读缓存")
    //   this.APinfo = JSON.parse(window.sessionStorage.getItem(key))
    // }
  },
};
</script>

<style scoped>
.footbox {
  min-height: 110px;
  max-height: 110px;
  width: 100%;
  background-image: url("../assets/images/footbg.jpg");
  background-size: 100% auto;
}

.foottop {
  justify-content: center;
  padding: 3px 0 3px 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  position: relative;
}

.footcol {
  color: #ffffff;
}

.footcol:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 100px;
  letter-spacing: 2px;
}

.logo {
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.logo img {
  width: 220px;
}

.gsname {
  /*line-height: 50px;*/
  color: #000000;
  /*margin-bottom: 20px;*/
}

.footcol:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 600px;
  letter-spacing: 2px;
}

.footcol:nth-child(2) .foot_text {
  font-size: 12px;
  line-height: 30px;
}

.footcol:nth-child(2) .foot_text:nth-child(1) {
  /*line-height: 10px;*/
}

.footcol:last-child {
  height: 100%;
  position: absolute;
  right: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ewm {
  width: 60px;
  height: 60px;
}

.ewm_title {
  /*line-height: 30px;*/
  letter-spacing: 2px;
  font-size: 12px;
}

.footbottom {
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  /*min-height: 90px;*/
  text-align: center;
  font-size: 12px;
}
.footbottomS{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.bahgs,
.bah {
  /*line-height: 40px;*/
  padding: 0 20px 0 20px;
}
@media (max-width: 600px) {
  /* 当屏幕宽度小于等于768px时应用此样式 */
  .footbottomS{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .bah {
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
