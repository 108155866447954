import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeFloat from '../views/HomeFloat.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'HomeFloat',
    component: HomeFloat
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login.vue')
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('@/views/user/regist.vue')
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/user/forgetpwd.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import('@/views/yuanqu/serve.vue')
  },
  {
    path: '/zoujinyq',
    name: 'zoujinyq',
    component: () => import('@/views/yuanqu/zoujinyq.vue')
  },
  {
    path: '/zoujinyqfloat',
    name: 'zoujinyqfloat',
    component: () => import('@/views/yuanqu/zoujinyqfloat.vue')
  },
  {
    path: '/yqdt',
    name: 'yqdt',
    component: () => import('@/views/yuanqu/yqdt.vue')
  },
  {
    path: '/yqdtfloat',
    name: 'yqdtfloat',
    component: () => import('@/views/yuanqu/yqdtfloat.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/yuanqu/question.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('@/views/user/userinfo.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/templateExample',
    name: 'templateExample',
    component: () => import('@/views/templateExample.vue')
  },
  {
    path: '/AboutSiwei',
    name: 'AboutSiwei',
    component: () => import('@/views/siwei/AboutSiwei.vue')
  },
  {
    path: '/zoujinyqSiwei',
    name: 'zoujinyqSiwei',
    component: () => import('@/views/siwei/zoujinyqSiwei.vue')
  },
  {
    path: '/informationNewsDetails',
    name: 'informationNewsDetails',
    component: () => import('@/components/informationNewsDetails.vue')
  },
  {
    path: '/SupportingServiceDetails',
    name: 'SupportingServiceDetails',
    component: () => import('@/components/SupportingServiceDetails.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
