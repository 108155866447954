<template>
<!--  顶部组件-->
  <div class="headdiv" :style="{width:`${widthDu}px`}">
    <div class="headdiv1" @click="headHidden">
       <img :class="tabbarShow===true?'headimg headdiv1Img':'headimg'" src="@/assets/img/1111.jpg">
    </div>
    <div class="headbox" v-show="tabbarShow">
      <!-- <span class="selectaddress">
        你好，杭州
      </span>
      <img class="logoimg" src="@/assets/img/wsyglogo.png" />
      <span class="selectaddress">
        盼云跨境（中国杭州）电子商务产业园
      </span> -->
      <ul class="tabbar">
        <!-- <img class='logo2' src='@/assets/images/logo2.png' /> -->
        <!--        <span :class=" tabbar_active == 0 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(0)">首页</span>-->
        <!--        <span :class=" tabbar_active == 1 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(1)">产品核心</span>-->
        <!--        <span :class=" tabbar_active == 2 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(2)">最新资讯</span>-->
        <!--        <span :class=" tabbar_active == 3 ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(3)">常见问题</span>-->
        <li :class=" tabbar_active ==index ? 'tabbaritem tabbaritem_active' : 'tabbaritem'" @click="push(index)" v-for="(item,index) in headList" :key="index">{{item.title}}</li>

      </ul>
      <ul class="userbox tabbar" v-if="!this.$store.state.user.token" style="display: none">
        <li class="loginbtn" @click="login">登录</li>
        <li class="resistbtn" @click="regist">注册</li>
      </ul>
      <span class="userbox" v-else>
        <span class="userinfobtn" @click="userinfo">
          <!-- <img class="headimg" src="https://org.modao.cc/uploads4/images/5553/55534051/v2_qifjoj.png"/> -->
          <!-- 个人中心 -->
          {{$store.state.user.userinfo.userName||$store.state.user.userinfo.nickName||'个人中心'}}
        </span>
        <span class="loginoutbtn" @click="loginout">注销</span>
      </span>
    </div>
  </div>

</template>

<script>
export default {
    data() {
        return {
          widthDu:0,
          tabbarShow:false,
          path: ['/',
            '/zoujinyqSiwei',
            '/yqdt',
            '/question',
            '/AboutSiwei',
            // '/templateExample'
          ],
          tabbar_active: 0,
          headList:[
            {id:'first',name:'first',title:'首页',path:'/'},
            {id:'zoujinyqSiwei',name:'zoujinyqSiwei',title:'产品核心',path:'/zoujinyqSiwei'},
            {id:'yqdt',name:'yqdt',title:'最新资讯',path:'/yqdt'},
            {id:'question',name:'question',title:'常见问题',path:'/question'},
            {id:'AboutSiwei',name:'AboutSiwei',title:'关于我们',path:'/AboutSiwei'},
            // {id:'templateExample',name:'templateExample',title:'模板',path:'/templateExample'},
          ]
        }
    },
    methods:{
      getTabClass(index)
      {
        // this.tabbar_active ==index ? 'tabbaritem tabbaritem_active' : 'tabbaritem'

      },
        login(){
          this.$router.push({
            path: '/login'
          })
        },
        regist(){
          this.$router.push({
            path: '/regist'
          })
        },
        userinfo(){
          // this.$router.push({
          //   path: '/userinfo'
          // })
        },
        loginout(){
          //退出登录
          this.$store.commit('user/set_loginout')
        },
        push(index){
          this.$router.push({
            path: this.path[index]
          })
          this.tabbar_active = index;
        },
      headHidden:function (){
        console.log("显示隐藏tabber:"+this.tabbarShow)
        if(this.tabbarShow===true){
          this.tabbarShow=false
          this.widthDu=0
        }else {
          this.tabbarShow=true
          this.widthDu=window.innerWidth
        }
      },
      handleResize() {
        console.log("页面监听:"+window.innerWidth)
        if(window.innerWidth>600){
          this.tabbarShow=true;
          this.widthDu=window.innerWidth
        }else {
          this.tabbarShow=false;
          this.widthDu=0
        }
      },
    },
    mounted(){
      console.log(this.path.indexOf(this.$router.currentRoute.path));
      if(this.path.indexOf(this.$router.currentRoute.path) == -1){
        this.tabbar_active = -1;
      }else{
        this.tabbar_active = this.path.indexOf(this.$router.currentRoute.path);
      }

      this.$nextTick(() =>{
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      });
    },
  created() {
      if(document.documentElement.clientWidth>600){
        this.tabbarShow=true
      }
  }
}
</script>

<style scoped>
li{ list-style: none;}
  .headbox{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
  }

  .selectaddress{
    margin: 20px;
  }

  .logoimg{
    width: 40px;
    /* height: 50px; */
  }

  .tabbar{
    position: relative;
    /*margin-right: 300px;*/
    display: flex;
    align-items: center;
  }

  .tabbaritem{
    margin: 0 20px;
    cursor: pointer;
    color: #FFFFFF;
  }

  .userbox{
    position: relative;
    margin: 20px 0px;
  }

  .tabbaritem_active{
    border-bottom: solid 2px rgb(54, 178, 211);
    padding: 4px 0;
    color: #fffffff0;
    font-weight: 900;
    font-size: 14px;
  }

  .resistbtn,.loginbtn,.userinfobtn,.loginoutbtn{
    /* display: inline-block; */
    padding: 5px 7px;
    /* background-color: #FFFFFF; */
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
  }

  .loginbtn,.userinfobtn{
    /* border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; */
    margin-right: 15px;
  }

  .resistbtn,.loginoutbtn{
    /* border-bottom-right-radius: 10px;
    border-top-right-radius: 10px; */
  }

  .userinfobtn{
    padding-left: 35px;
    position: relative;
  }

  .headimg{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 5px;
    box-sizing: border-box;
    border: 2px solid #FFFFFF;
  }

  .logo2{
    width: 120px;
  }

  .userbox,.logo2{
    /*margin-right: 120px;*/
  }
  .headdiv{
    position: fixed;
    z-index: 999;
    background-color: #00000042;
    top: 0;
  }
.headdiv1{
 width: 100%;
  height: 50px;
  display: none;
}
.headdiv1Img{
  transform: rotateX(180deg);
}
  @media (max-width: 600px){
    li{
      margin: 10px;
    }
    .tabbar{
      align-items: flex-start;
      flex-direction: column;
    }
    .tabbaritem{
      font-size: 16px;
      margin: 5px 0;
    }
    .tabbaritem_active{
      font-size: 18px;
      color: #ffffff;
    }
    .headdiv{
      background-color: #00000099;
    }
    .headdiv1{
      display: block;
    }
    .headbox{
      /*display: none;*/
    }
  }
</style>
