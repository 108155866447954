<template>
<!--  首页组件(旧)-->
    <div class="indexbox">
        <!-- 滑块 -->
      <banner positionSid="6433"
              showTitle="true"
              :title="this.$store.state.project.projectName"
              :englishName="this.$store.state.project.projectEnglishName"
              :content="this.$store.state.project.projectDesc">

      </banner>
        <!--  运营模式-->
        <yyms :titleEnglish="this.$store.state.project.titleEnglishIsShow" bjImgHidden="1"></yyms>

        <!--配套服务-->
        <SupportingService isRecommended="1"></SupportingService>

        <!--关于我们-->
<!--      <gywm-->
<!--          :titleEnglish="this.$store.state.project.titleEnglishIsShow"-->
<!--          bjImgHidden="1"></gywm>-->


        <!--福利政策-->
      <flzc
          :titleEnglish="this.$store.state.project.titleEnglishIsShow"
          bjImgHidden="1"></flzc>
        <!-- 申请流程  合作流程-->
        <flowListTemplate
                type="1"
                :titleEnglish="this.$store.state.project.titleEnglishIsShow"></flowListTemplate>



        <!--弹窗-->
<!--      <elDialogVue title="标题" visible="true" content="内容"></elDialogVue>-->
<!--        <el-dialog-->
<!--          class="rytz_model"-->
<!--          top='30vh'-->
<!--          title=""-->
<!--          :visible="modelconfig.show"-->
<!--          :show-close='false'-->
<!--          width="100%"-->
<!--          center-->
<!--        >-->
<!--          <span>这是一段信息反反复复烦烦烦烦烦烦烦烦烦烦烦烦烦烦烦反反复复烦烦烦烦烦烦烦烦烦烦烦烦烦烦烦呱呱呱呱呱呱呱呱呱呱呱呱呱呱呱古古怪怪</span>-->
<!--          <span slot="footer" class="dialog-footer">-->
<!--            <el-button type="primary" @click="iknow" round>我 知 道 了</el-button>-->
<!--          </span>-->
<!--        </el-dialog>-->

        <!-- 申请执照 -->
        <!-- <div class="sqzzwrap"> -->
          <!-- <img class="sqzzbtn" src="../assets/images/sqzz.png" @click="to_zzsq" /> -->
          <!-- <img class="sqzzbtn2" :src="sqzz2_img" > -->
        <!-- </div> -->
        <!-- 侧边导航 -->
        <sidebar ref="mysiderbar"></sidebar>
    </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
//import { yqjsapi , getprojectspi , ryznapi , yqhlapi , indexserviceapi , swiperapi } from '@/api/index.js';
import { sqzzurl , staticurl,statiVcurl } from '@/utils/baseurl'
import wow from 'wowjs'
import  {isAssetTypeAnImage,} from '@/utils/common.js'

import sidebar from '@/components/sidebar'
import SupportingService from "@/components/SupportingService.vue";
import banner from "@/components/banner.vue";
import flowListTemplate from "@/components/flowListTemplate.vue";
import gywm from "@/components/gywm.vue";
import flzc from "@/components/flzc.vue";
import elDialogVue from "@/components/elDialog.vue";
import yyms from "@/components/yyms.vue";

export default {
    components:{
      yyms,
      elDialogVue,
      flzc,
      gywm,
      flowListTemplate,
      banner,
      SupportingService,
      sidebar
    },
    data() {
        return {
            mySwiper: {},
          bannerIMGheight:document.documentElement.clientHeight+'px',
            swiper: {
              imgs:[],
            },
            // yqjs: {
            //   text: '',
            //   img: '',
            // },
            // yqhl:{
            //   list:[],
            //   img: '',
            // },
            service:{
              list: []
            },
            // modelconfig:{
            //   show: true,
            // },
            sqzz2_img: "./assets/images/sqzz2_white.png",
        }
    },
    methods:{
        isIMG(ext)
        {
            var isOK= isAssetTypeAnImage(ext);
            console.log(isOK)
            return isOK
        }
        ,
        toENWords(s)
        {//把数字  转英文单词
            var dg = ['zero','one','two','three','four','five','six','seven','eight','nine'];
            var tn =['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen','seventeen','eighteen','nineteen'];
            var tw = ['twenty','thirty','forty','fifty','sixty','seventy','eighty','ninety'];
            var th = ['','thousand','million', 'milliard','billion'];
            s = s.toString();

            s =s.replace(/[\, ]/g,'');
            if (s != parseFloat(s)) return 'not a number'; var x =s.indexOf('.');
            if (x == -1) x = s.length; if (x > 15)
                return 'too big';
            var n =s.split('');
            var str = '';
            var sk = 0;
            for (var i=0; i < x; i++) {

                if ((x - i) % 3 == 2) {
                    if (n[i] == '1') {
                        str += tn[Number(n[i + 1])] + ' ';
                        i++;
                        sk = 1;
                    } else if (n[i] != 0) {
                        str += tw[n[i] - 2] + ' ';
                        sk = 1;
                    }
                } else if (n[i] != 0) {
                    str += dg[n[i]] + ' ';
                    if ((x - i) % 3 == 0) str += 'hundred ';
                    sk = 1;
                }
                if ((x - i) % 3 == 1) {
                    if (sk) str += th[(x - i - 1) / 3] + ' ';
                    sk = 0;
                }
            }// for
            if (x != s.length) {
                var y = s.length;
                str += 'point ';
                for (var i = x + 1; str=str.replace(/\s+/g, ' ');) {

                }
            }
            return  str
        }
        ,
      serve(){
        this.$router.push({
          path: '/serve'
        })
      },
        hideChat(){
            // 相等代表是 父级节点触发
          // if(e.currentTarget == e.target)
          // {
               this.$refs.mysiderbar.hideChat()
          // }


        },
      // iknow(){
      //   this.modelconfig.show = false;
      // },
      that_scroll(wrapdom,that_dom,callback1,callback2) {
        wrapdom.onscroll = function(){
          var curr_element = that_dom;
          var scroll_t = document.documentElement.scrollTop;
          var viewheight =document.documentElement.clientHeight;
          var self_top = curr_element.offsetTop;
          //超出被观测对象顶部边缘
          if( (scroll_t + viewheight) > self_top ){
            // console.log('超出')
            callback1()
          }else{
            //  console.log('未超出')
            callback2();
          }
        }
      },
      to_zzsq(){
        if(this.$store.state.user.token){
          location.href = sqzzurl();
        }
        else{
          this.$router.push({
            path: '/login'
          })
        }
      }
    },
    beforeMount(){
      let projectid = this.$store.state.project.projectid;

      if(this.$store.state.project.servedata){
        // yqjsapi({apartment_Sid : projectid}).then((res) => {
        //   this.yqjs.text = res.data.result[0].about_Content;
        //   this.yqjs.img = staticurl + res.data.result[0].about_Logo;
        // })
          // 入园指南 合作流程
        // getprojectspi({apartment_Sid : projectid})
        // ryznapi({apartment_Sid : projectid}).then((res) => {
        //   this.ryzn.list = res.data.result;
        // })
        // yqhlapi({apartment_Sid : projectid}).then((res) => {
        //   this.yqhl.list = res.data.result
        //   this.yqhl.img = staticurl + res.data.result[0].bonus_Logo;
        // })
        // indexserviceapi({apartment_Sid : projectid}).then((res) => {
        //   this.service.list = res.data.result.map((item,index) => {
        //     item.service_Logo = staticurl + item.type_Image;
        //     return item;
        //   })
        // })
        // swiperapi({apartment_Sid : projectid, position_Sid: '1'}).then((res) => {
        //   this.swiper.imgs = res.data.result.map((item) => {
        //     item.imgurl = staticurl + item.picture;
        //
        //       //视频判断
        //     // console.log('------------------测试--------------------------')
        //     // item.imgurl =  'https://uwooz.oss-cn-hangzhou.aliyuncs.com/image/cmd_rm.gif'
        //     // item.imgurl = require('../assets/images/homeBg1.gif')
        //     console.log( item.imgurl)
        //     return item;
        //   })
        // })
      }
      else{
        //  this.swiper.imgs = [
        // // {imgurl: './assets/images/homeBg1.gif'},
        //   {imgurl: './assets/images/swiper1.jpg'},
        //   {imgurl: './assets/images/swiper2.jpg'},
        //   {imgurl: './assets/images/swiper3.jpg'}
        // ];
        // this.yqjs.text = `<div class="ustext1">
        //                盼云跨境，是一家以抖音小店店主、抖音主播为服务对象的综合电子商务产业园，为抖音百万商家及主播提供包括工商注册、财税服务、知识产权、供应链等一站式配套服务，免费提供注册地址，入园
        //                享受多项福利政策。盼云跨境依托专业的核心服务团队，借助庞大的商家主播群体，全方位打造一个集电商直播、供应链于一体的新零售产业服务平台，以点带面，实现直播电商产业服务升级。
        //              </div>
        //              <div class="ustext2">
        //                盼云跨境旗下“抖音小号”，作为其移动端产品，集品宣、一键入园服务选购、客服咨询四为一体，为广大入园商家提供更便捷、更全面的运营指南。
        //              </div>`;
        // this.yqjs.img = './assets/images/us.png';
        // this.yqhl.list = [
        //   {
        //     bonus_Name: "财税奖励计划",
        //     bonus_Content: "园区支持电商经营主体入驻可享受财政奖励政策;针对年实际税费贡献在20万元以上的纳税人，给予地方留存部分的60%到80%的奖励。",
        //   },{
        //     bonus_Name: "人才引培计划",
        //     bonus_Content: "针对电商人才培训机构,符合人才引培要求的给予机构每年不超过30万的补助,针对贡献大的电商企业，择优选择符合条件的核心人才,享受相关住房政策。",
        //   },{
        //     bonus_Name: "楼宇扶持计划",
        //     bonus_Content: "园区对优质的电子商务企业给予为期三年，每年不超过90万元的定额租金补助政策,扶持园区电商企业成长。",
        //   },{
        //     bonus_Name: "亿元补贴计划",
        //     bonus_Content: " 园区启动亿元专项补贴计划。针对首批入园商家,入驻园区的每个市场主体可领取1000元补贴,用于抵扣园区内税务、审计、商标注册等服务。",
        //   }
        // ];
        // this.yqhl.img = './assets/images/flzc.png';
        // this.service.list = [
        //   {
        //     service_Logo:'./assets/images/zxyhkh.png',
        //     type_Name:'在线银行开户',
        //   },
        //   {
        //     service_Logo:'./assets/images/fpxgyw.png',
        //     type_Name:'发票相关业务',
        //   },
        //   {
        //     service_Logo:'./assets/images/dzqzfw.png',
        //     type_Name:'电子签章服务',
        //   },
        //   {
        //     service_Logo:'./assets/images/zscqfw.png',
        //     type_Name:'知识产权服务',
        //   }
        // ];
      }
    },
    mounted(){
        let that = this;
        this.mySwiper = new Swiper('.swiper-container', {
            // initialSlide: 0,
            // direction: 'vertical',
            // effect : 'coverflow',
            effect : 'fade',
            mousewheelControl : true,
            autoplay:{
              delay: 2000,
            },
            on:{
	        	init:function(swiper){
                //     // console.log(swiper)
                //     let slide = swiper.slides[0].classList;
                //     // console.log(slide)
	        	  	// slide.add('ani-slide');
	        	},
	        	transitionStart: function(swiper){
	        	  	// for(let i = 0; i < swiper.slides.length; i++){
	        	  	//   	let slide = swiper.slides[i].classList;
	        	  	//     slide.remove('ani-slide');
	        	  	// }
	        	},
	        	transitionEnd: function(swiper){
                //   // console.log(swiper)
	        	  	// let slide = swiper.slides[swiper.activeIndex].classList;
	        	  	// slide.add('ani-slide');
	        	},
	        }
        })
        this.that_scroll(document.getElementsByTagName('body')[0],document.getElementsByClassName('indexbox')[0],function(){
          that.sqzz2_img = "./assets/images/sqzz2_purple.png"
        },function(){
          that.sqzz2_img = "./assets/images/sqzz2_white.png"
        });
        this.$nextTick(() => {
          new wow.WOW().init()
        })

        // 页面增加全局点击
        window.addEventListener('click', this.hideChat)
    },
    beforeDestroy() {
        /* 组件销毁的时候要移除侦听器 */
        window.removeEventListener('click', this.hideChat)
    },
}

</script>

<style scoped>
  .indexbox{
    width: 100%;
    overflow-x: hidden;
  }
</style>
